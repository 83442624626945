import SvgComponent from "../SvgComponent"
import React from "react";
import { useSelector } from "react-redux";
import { GetAppText } from "../../../utils";
export const AlertComponent=({text,svgName,svgColor,styles,isArb})=>{

    const language = useSelector((state) => state.language.value);

return(
    <div className={`flex flex-wrap items-center  font-bold py-2 px-3 rounded-lg ${styles}  text-F14  space-x-2 gap-y-5   ${  language === "_ar" ? "flex-row-reverse justify-start space-x-reverse" : "" } `}>
    <SvgComponent
        name={svgName}
        className={"w-5 h-5"}
        stroke={svgColor}
        fill={"none"}
      />
<div>
{isArb? GetAppText(text):text}
</div>
      </div>
)


}