import React, { useEffect, useRef, useState } from "react";
import { convertToArabicNumbers, convertToNumbersInArabic, GetAppText, ValidationErrors } from "../../../../../utils";
import { useSelector,useDispatch } from "react-redux";
import { StyleClass } from "../../../../../utils";
import { InputComponent } from "../../../../components/InputComponent";
import { ButtonComponent } from "../../../../components/ButtonComponent";
import Dropdown from "../../../../components/Dropdown";
import SvgComponent from "../../../../components/SvgComponent";
import { postRequest } from "../../../../../apis/methods";
import { apiRoutes } from "../../../../../apis/routes";
import { modalHandler } from "../../../../../store/slices/modalSlice";
import { dataHandler } from "../../../../../store/slices/dataSlice";
import { ModalComponent } from "../../../../components/Modal";
import { Otp } from "../../../Otp";
import { handleSuccess } from "../../../../components/Toast/toastHelper";
import { ToastContainer } from "react-toastify";
export const PersonalInformation = ({data,countrydata}) => {

  const [cprlimit, setCprlimit] = useState("");
  const [nationalityName, setNationalityName] = useState("");
  const[digitLenght,setDigitLenght]=useState(9)




  useEffect(()=>{
   console.log("CPRRR",data?.cpr_number_digits,countrydata,data)
    setName(data?.name)
    setCountryId(data?.country?.id)
    setSelectedValue(data?.country?.phone_code) 
    setPhoneNumber( data?.phone_number)
    setEmail(data?.email)
    setDigitLenght(data?.country?.phone_digits)
    setPersonal_CPR(data?.cpr_number)
 
    setSelectedValue3(data?.nationality?.id)
    setNationalityName(data?.nationality?.name)
    setCprlimit(data?.nationality?.cpr_number_digits)
  },[])
  const [name, setName] = useState("");

  const isOpen = useSelector((state) => state.openModel.value);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [personal_CPR, setPersonal_CPR] = useState("");
  const [selectedValue2, setSelectedValue2] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const language = useSelector((state) => state.language.value);
  const textAlignStyle = language === "_ar" ? "right" : "left";
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOtpModal, setIsOtpModal] = useState(false);
 
  const [selectedValue3, setSelectedValue3] = useState(1);
  const[countryId,setCountryId]=useState('')
const[loading,setLoading]=useState(false)
const inputRef = useRef(null);
const dispatch = useDispatch();

useEffect(()=>{

  if(isOpen?.type==="updateOtpToast"){
   
    // handleSuccess(isOpen?.msg,language)
setIsOtpModal(false)
  }

},[isOpen])

useEffect(()=>{
  setSelectedValue2(language==="_ar"?data?.nationality?.name_ar:data?.nationality?.name)
},[language])
  const handler=()=>{

    setIsDropdownOpen(!isDropdownOpen);
  }

  const handleSelect = (value) => {
    setCountryId(value?.id)
    setDigitLenght(value?.phone_digits)
    setSelectedValue(value?.phone_code);
    setPhoneNumber('')
    // inputRef.current.focus();
    setIsDropdownOpen(false);
  };


  const handleSubmit = async() => {
    const formattedPhone = Number(selectedValue);
   
    const number=convertToArabicNumbers(phoneNumber)
    const combinedPhone = `${selectedValue}${number}`; 
    const strNum=convertToArabicNumbers(personal_CPR);
    const payload={
    
      name: name,
      // country: selectedValue2,
      email: email,
      nationality_id:selectedValue3,
      // phone_code: selectedValue,
      phone:combinedPhone,
      country_id:countryId,
      cpr_number:strNum
    
    }
    setLoading(true)
    if(name && email && personal_CPR && selectedValue && phoneNumber && selectedValue2)
    {
      try {
      const response = await postRequest(apiRoutes.update_profile,payload); // Replace with your endpoint
    if(response.status==="success")
    { 
    setLoading(false)
if(response?.data?.is_request_for_update_phone_number){
  if (
    response?.message.includes(ValidationErrors.updateProfile.phoneUpdated)
  ) {
    
    dispatch(dataHandler(GetAppText('phoneUpdated')));
    // dispatch(modalHandler({ type: "updateToast", msg: GetAppText('phoneUpdated')}));


    // setTimeout(()=>{
    //     dispatch(modalHandler(""));
    //   },[500])


    
  } 

  
 setIsOtpModal(true)
}
else
{  
let error = '';

if (
  response?.message.includes(ValidationErrors.updateProfile.update)
) {
  error = GetAppText('updateProfile');
} 
dispatch(modalHandler({ type: "updateToast", msg: error}));


    setTimeout(()=>{
        dispatch(modalHandler(""));
      },[500])
    
    }


    }
    else{
      let error = '';
 
      if (
        response?.data?.message?.includes(ValidationErrors.phoneNumber.notComplete)
      ) {
        error = GetAppText('phone_not_valid');
      } else if (
        response?.data?.message?.includes(
          ValidationErrors.phoneNumber.alreadyExists,
        )
      ) {
      
        error = GetAppText('phone_already_exists');
      
      } else if (
        response?.data?.message?.includes(ValidationErrors.email.invalid)
      ) {
        error = GetAppText('email_must_be_valid');
      } 
      else {
        error= response?.data?.message?.replace(/-/g, " ");
      }
      dispatch(modalHandler({ type: "updateToastError", msg: error}));
      setTimeout(()=>{
          dispatch(modalHandler(""));
        },[500])
    setLoading(false)
    }
    } catch (error) {
      setLoading(false)
      console.error('Error fetching data:', error);
    }
    }
    else{
      setLoading(false)
      dispatch(modalHandler("Error"));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])

    }
     
        // Here, you can send the payload to an API or perform any other action needed.
      };



  const handler3=()=>{

    setIsDropdownOpen3(!isDropdownOpen3);
  }

  const handleSelect2 = (value) => {
  
    // setCountryId(value?.id)
    setPersonal_CPR("")
    setSelectedValue3(value?.id)
    setCprlimit(value?.cpr_number_digits)
    // setDigitLenght(value?.phone_digits)
    // setSelectedValue(value?.phone_code);
    // setPhoneNumber('')
    setSelectedValue2(value?.name);
    setIsDropdownOpen3(false);
    inputRef.current.focus()
  };

  const handleKeyDown = (event) => {
    if (["e", "E", ".", "-", "+"].includes(event.key)) {
      event.preventDefault();
    }
  
  };
//   const handleInputChange = (e) => {
//     const value = e.target.value;
  
//     // Allow only numbers and limit to 8 digits
//     const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
//     if (numericValue.length <= digitLenght){
// if(language==="_ar")
// {
//   setPhoneNumber(convertToNumbersInArabic(value))
// }
// else{
//       setPhoneNumber(value);
// }



//     }
//   };

const arabicDigits = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
const englishDigits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
const handleInputChange = (e) => {
  let value = e.target.value;

  // If language is Arabic, convert Arabic digits back to English for processing
  if (language === "_ar") {
    value = value
      .split("")
      .map((char) => {
        const index = arabicDigits.indexOf(char);
        return index > -1 ? englishDigits[index] : char;
      })
      .join("");
  }

  // Remove non-numeric characters and limit to 8 digits
  const numericValue = value.replace(/[^0-9]/g, "");
  if (numericValue.length <= digitLenght) {
    if (language === "_ar") {
      // Convert English digits to Arabic digits for display
      const arabicValue = numericValue
        .split("")
        .map((digit) => arabicDigits[Number(digit)])
        .join("");
        console.log("arabicValue",arabicValue)
      setPhoneNumber(arabicValue);
    } 
    else {
      setPhoneNumber(numericValue);
    }
  }
};





  return (
    <div className="w-full sm:space-y-6 space-y-4 h-full">
 
      <h1
        className={`text-primary text-F24 font-bold ${
          language === "_ar" ? "text-right" : "text-left"
        }`}
      >
        {GetAppText("personal_information")}
      </h1>
      <div className="">
        <div className="bg-white p-6 rounded-xl">
        <h1
        className={`text-secondaryTextColor pb-7 text-F18 font-semibold ${
          language === "_ar" ? "text-right" : "text-left"
        }`}
      >
        {GetAppText("personal_information")}
      </h1>
          <div className="space-y-6">
            <div className={`md:flex items-center md:space-x-4 md:space-y-0  space-y-5 ${
          language === "_ar" ? "flex-row-reverse md:space-x-reverse" : ""
        }`}>
              <div className="space-y-2 w-full">
                <span
                  className="text-black text-F14 block"
                  style={{
                    textAlign: textAlignStyle,
                  }}
                >
                  {GetAppText("name")}
                </span>
                <InputComponent
                  input_style={"!py-3"}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="space-y-2 w-full">
                <span
                  className="text-black  text-F14 block "
                  style={{
                    textAlign: textAlignStyle,
                  }}
                >
                  {GetAppText("nationality")}
                </span>
                <div className="w-full">
            <ButtonComponent onClick={handler3}  title={selectedValue2===""?GetAppText("closest_complete"):selectedValue2}     
            svg={ <SvgComponent
            name={"ChevronDown"}
            className={`w-2.5 h-2.5`}
            stroke={"#1B1C1E"}
            fill={"none"}
          />} 
           styles={"py-3 w-full flex !text-sm !normal-case !border-2 !border-borderColor !rounded-lg justify-between px-4 text-sm whitespace-nowrap overflow-hidden text-ellipsis"} type="secondary_transparent"/>
            <Dropdown countryName={true} mainStyle={`w-full  !h-[200px]`}  handleSelect={handleSelect2}  isOpen={isDropdownOpen3} options={countrydata} setIsDropdownOpen={setIsDropdownOpen3} />
            </div>
             
              </div>
            </div>
            <div className={`md:flex items-center  md:space-x-4 md:space-y-0 space-y-5 ${
          language === "_ar" ? "flex-row-reverse md:space-x-reverse" : ""
        }`}>
              <div className="space-y-2 relative w-full">
                <span
                  className="text-black text-F14 block"
                  style={{
                    textAlign: textAlignStyle,
                  }}
                >
                  {GetAppText("phone_number")}
                </span>
                <div>
<div className="flex items-center  py-1  border-2 border-borderColor  rounded-lg ">
  <div onClick={handler}  className="flex cursor-pointer   items-center px-2.5 space-x-2 border-e border-e-borderColor">
    <div className="text-sm">
      {/* {selectedValue} */}
      {language==="_ar"?convertToNumbersInArabic(selectedValue):convertToArabicNumbers(selectedValue)}
    </div>
  <SvgComponent
            name={"ChevronDown"}
            className={`w-2.5 h-1.5`}
            stroke={"black"}
            fill={"none"}
          />
  </div>
                <input
                // disabled
          type={"text"}
          id={"phone"}
       
          value={language==="_ar"?convertToNumbersInArabic(phoneNumber):convertToArabicNumbers(phoneNumber)}
          onChange={handleInputChange}
          className={`block no-spinner w-full border-none focus:border-borderColor focus:outline-none focus:ring-0  placeholder:text-F14 text-sm placeholder:text-lightTextColor ${
            language === "_ar" ? "pe-2 text-right" : "ps-3"
          } `}
       
          name={"phone"}
          onKeyDown={handleKeyDown}
        />
              
        </div>
        {isDropdownOpen&& <Dropdown phone={true} mainStyle={`w-full !h-[200px]`}  handleSelect={handleSelect}  isOpen={isDropdownOpen} options={countrydata} setIsDropdownOpen={setIsDropdownOpen} />}
      
        </div>
              </div>
              <div className="space-y-2 w-full">
                <span
                  className="text-black text-F14 block"
                  style={{
                    textAlign: textAlignStyle,
                  }}
                >
                  {GetAppText("email")}
                </span>
                <InputComponent
                  input_style={"!py-3"}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div
              className={`md:flex w-full ${
                language === "_ar" ? "justify-end" : ""
              }`}
            >
              <div className={`md:w-6/12 ${language === "_ar" ? "pl-2" : "pr-2"}`}>
                <div className="space-y-2 w-full">
                  <span
                    className="text-black text-F14 block"
                    style={{
                      textAlign: textAlignStyle,
                    }}
                  >
                    {GetAppText("personal_number_CPR")}
                  </span>
                  <InputComponent
                    input_style={"!py-3 "}
                    maxLength={cprlimit || data?.cpr_number_digits}
                    // disabled={true}
                    ref={inputRef}
                    // value={personal_CPR}
                    value={language==="_ar"?convertToNumbersInArabic(personal_CPR):convertToArabicNumbers(personal_CPR)}
                    onChange={(e) => setPersonal_CPR(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={`md:flex w-full ${language === "_en" ? "justify-end" : ""}`}
          >
            <div className="md:w-fit w-full md:pt-0 pt-6">
              <ButtonComponent
              onClick={handleSubmit}
              loading={loading}
              disabled={loading}
                type="secondary"
                title={GetAppText("save_changes")}
                styles={"!text-F18 !w-full !px-6"}
              />
            </div>
          </div>
        </div>
      </div>
 {isOtpModal&&     <ModalComponent styles="!w-[460px]" isOpen={isOtpModal} onClose={()=>{setIsOtpModal(false);dispatch(modalHandler(""))}}>
  <Otp update={isOtpModal} info={{ph:phoneNumber,code:selectedValue,id:countryId}}/>
  </ModalComponent>}
    </div>
  );
};
