import React, { useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { convertToArabicNumbers, convertToNumbersInArabic, GetAppText, ValidationErrors } from "../../../utils";

import { ButtonComponent } from "../../components/ButtonComponent";
import { useParams } from 'react-router-dom';
import SvgComponent from "../../components/SvgComponent";
import { SeparatorComponent } from "../../components/Separator";
import { ProgressBar } from "../../components/ProgressBar";
import { DonationCard } from "../../components/DonationCard";
import { InputComponent } from "../../components/InputComponent";

import { apiRoutes } from "../../../apis/routes";
import { getRequest, postRequest } from "../../../apis/methods";
import { Loader } from "../../components/Loader";
import { useNavigate } from 'react-router-dom';
import { modalHandler } from "../../../store/slices/modalSlice";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleClick, handleSuccess } from '../../components/Toast/toastHelper';
import DonationModal from "../../components/DonationModal";
import { Image_URL } from "../../../utils/BaseUrl";
const ProjectDetails = () => {
    const settingData = useSelector((state) => state?.setting?.value);
  const language = useSelector((state) => state.language.value);
  const isOpen = useSelector((state) => state.openModel.value);
  const Info = useSelector((state) => state.info.value);
  const [loading,setLoading]=useState(false)
  const[data,setData]=useState('')
  const [addloading,setAddLoading]=useState(false)
  const [donateloading,setDonateLoading]=useState(false)
  const dispatch = useDispatch();
  const [price,setPrice]=useState("")
  const[similarData,setSimilarData]=useState('')
  const [isAddModal, setIsAddModal] = useState(false);
  const [limit, setLimit] = useState('');
  const[details,setDetails]=useState('')
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(()=>{
    fetchData()

  },[id])



  useEffect(()=>{
    if(isOpen?.type==="detailtoast"){
      handleSuccess(isOpen?.msg,language)
    }
    else if(isOpen?.type==="detailError"){
      handleClick(isOpen?.msg,language)
    }
    else if(isOpen==="detailpriceError"){
      handleClick(GetAppText("req_price"),language)
    }
    else if(isOpen?.type==="toast"){
      handleSuccess(isOpen?.msg,language)
    }
    else if(isOpen?.type==="share"){
      handleSuccess(isOpen?.msg,language)
    }
    else if(isOpen?.type==="toastError"){
      handleClick(isOpen?.msg,language)
    }
    else if(isOpen==="priceError"){
      handleClick(GetAppText("req_price"),language)
    }
  },[isOpen])
const [tagsName,setTags]=useState('')

  const fetchData = async () => {
 
    setLoading(true)
    try {
      const response = await getRequest(`${apiRoutes.get_projectDetails}?link=${id}`); // Replace with your endpoint
if(response.status==="success")
   { 
    setLoading(false)
  
    const projectData = response?.data?.project;
    if (response?.data?.project?.is_urgent === 1) {
      projectData.tags = [
     
        {
          id: "urgent", // Unique ID
          title: "Urgent",
          title_ar: "عاجل", // Arabic translation for "Urgent"
          slug: null,
          image: null,
          created_at: null,
          updated_at: null,
          deleted_at: null,
          pivot: null,
        },...projectData.tags,
      ];
      setTags(projectData.tags)
    }

else{
  setTags(projectData.tags)
}

    setData(response?.data?.project)
    if(response?.data?.project?.cart_item)
      { 
        setPrice(response?.data?.project?.cart_item?.price)}
    setSimilarData(response?.data?.similarProjects)
  
  }
  else{
    setLoading(false)
  }
    } catch (error) {
      setLoading(false)
      console.error('Error fetching data:', error);
    }
  };


  const handleSubmit = async (item) => {
    // Prevent multiple submissions
  if (addloading) return; 
  // Prevent multiple submissions
  
  const data = {
    cart_type:"CART",
    is_edit:item?.cart_item?true:false,
    items: [
        {
            id:item?.id,
            type:"PROJECT",
            price: price
        }
    ]}



    // const data = {
    //   itemable_type: "project",
    // itemable_id: item?.id,
    //   price: price  
    // };
    setAddLoading(true)
  if(price){
    try {
      const response = await postRequest(apiRoutes.add_cart, data); // Replace with your endpoint
   
   
      if (response.status === "success") { 
        setAddLoading(false)
        let error = '';
        if (
          response?.message?.includes(ValidationErrors.addCartItem.added)
        ) {
          error = GetAppText('added_cart');
         
        } 
        else if (
          response?.message?.includes(ValidationErrors.addCartItem.updated)
        ) {
          error = GetAppText('updated_cart');
         
        } 

        dispatch(modalHandler({ type: "detailtoast", msg: error}));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])
     
          // setPrice("");
   
      } else {
        setAddLoading(false)
        let error=""
        if (
          response?.data?.message?.includes(ValidationErrors.addCartItem.limit_error)
        ) {
          error = `${GetAppText('not_exceed_amount')}`;
     
        } 
       else if (
          response?.data?.message?.includes(ValidationErrors.addCartItem.exceedGoalAmount)
        ) {
          error = `${GetAppText('limit_price_cart')} ${item?.remaining_goal_amount?.toLocaleString()}.`;
     
        } 
        else {
          // error = GetAppText('something_went_wrong');
          error= response?.data?.message
        }

        dispatch(modalHandler({ type: "detailError", msg: error}));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])
          // setPrice("");
       
      }
    } 
    catch (error) {
      console.error('Error fetching data:', error);
    } }
    else{
        setAddLoading(false)
     
        dispatch(modalHandler("detailpriceError"));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])
  
    }
  
  };
  
  const handleDonate = async (item) => {
    // Prevent multiple submissions
  if (donateloading) return; 
  // Prevent multiple submissions
  
    // const data = {
    //   itemable_type: "project",
    // itemable_id: item?.id,
    //   price: price  
    // };
    const englishPhoneNumber = convertToArabicNumbers(price);

    const number = Number(englishPhoneNumber);

    const data = {
      cart_type:"DONATION",
      is_edit:item?.cart_item?true:false,
      items: [
          {
              id:item?.id,
              type:"PROJECT",
              price: number
          }
      ]}
  
    setDonateLoading(true)
  if(price){
    try {
      const response = await postRequest(apiRoutes.add_cart, data); // Replace with your endpoint
   
   
      if (response.status === "success") { 
        setDonateLoading(false)
   
        // dispatch(modalHandler({ type: "detailtoast", msg: response?.message}));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])
  
          setPrice("");
          navigate(`/checkout/donation`);
      } else {
        setDonateLoading(false)
        let error=""
        if (
          response?.data?.message?.includes(ValidationErrors.addCartItem.limit_error)
        ) {
          error = `${GetAppText('not_exceed_amount')}`;
     
        } 
       else if (
          response?.data?.message?.includes(ValidationErrors.addCartItem.exceedGoalAmount)
        ) {
          error = `${GetAppText('limit_price_cart')} ${item?.remaining_goal_amount?.toLocaleString()}.`;
     
        } 
        else if (
          response?.data?.message?.includes(ValidationErrors.addCartItem.lessAmount)
        ) {
          error = `${GetAppText('amount_less')}`;
     
        } 
        else {
          // error = GetAppText('something_went_wrong');
          error= response?.data?.message
        }   
        dispatch(modalHandler({ type: "detailError", msg: error}));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])
          // setPrice("");
       
      }
    } 
    catch (error) {
      console.error('Error fetching data:', error);
    } }
    else{
      setDonateLoading(false)
     
        dispatch(modalHandler("detailpriceError"));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])
  
    }
  
  };

      const handleProjectClick = (val) => {
      
        setDetails(val)
        setIsAddModal(true)
      dispatch(modalHandler("isDonate"));
        // navigate(`/donation-project/${id}`);
      };

      const ImageClick = (val) => {
   
        navigate(`/donation-project/${val?.slug}`);
      };

      const handleCartDetails=(items)=>{
      
        setDetails(items)
        setIsAddModal(true)
        dispatch(modalHandler(""));
        
        }

   


        const handle_share=()=>{
          const currentURL = window.location.href;
       
        const fullLink=`${currentURL}`
            // Copy the link to the clipboard
            navigator.clipboard.writeText(fullLink).then(() => {
              dispatch(modalHandler({type:"share",msg:GetAppText("link")}));
              setTimeout(()=>{
                dispatch(modalHandler(""));
              },100)
            });
        
        }
        
        const arabicDigits = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
        const englishDigits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        const handleInputChange = (e) => {
          let value = e.target.value;
        
          // If language is Arabic, convert Arabic digits back to English for processing
          if (language === "_ar") {
            value = value
              .split("")
              .map((char) => {
                const index = arabicDigits.indexOf(char);
                return index > -1 ? englishDigits[index] : char;
              })
              .join("");
          }
        
          // Remove non-numeric characters and limit to 8 digits
          const numericValue = value.replace(/[^0-9]/g, "");
    
            if (language === "_ar") {
              // Convert English digits to Arabic digits for display
              const arabicValue = numericValue
                .split("")
                .map((digit) => arabicDigits[Number(digit)])
                .join("");
                console.log("arabicValue",arabicValue)
                setPrice(arabicValue);
            } 
            else {
              setPrice(numericValue);
            }
          
        };
        



  return (
    <>
 <ToastContainer />
    <div className="md:px-20 px-5 py-16 w-full space-y-16">
           
{  loading?    
<div className="flex justify-center items-center h-[540px]">
      <Loader/>
      </div>:
      <div
        className={`md:flex ${
          language === "_ar" ? " " : "flex-row-reverse md:space-x-reverse "
        } md:space-x-10 md:space-y-0 space-y-8  w-full justify-between`}
      >
<div className="md:w-[65%]">
<img 

src={`${Image_URL}${data?.image}`}
className={`object-cover  rounded-lg  md:h-[710px] w-full h-[460px]`} alt="logo" />
        {/* {
          data?.media?.map((item)=>(
            <img src={item?.thumbnail} className={`object-cover  ${data?.media?.length===1?"rounded-lg  h-[725px]":""}`} alt="logo" />
          ))
        } */}
</div>

        <div className="w-full text-primary space-y-6">
          <div
            className={`flex sm:flex-row sm:space-y-0 space-y-3 space-y-reverse ${
              language === "_ar" ? "sm:flex-row-reverse  items-end" : ""
            } sm:items-center sm:justify-between flex-col-reverse `}
          >
            <h1 className="font-extrabold md:text-F24 text-F20 ">{ language === "_ar" ?data?.title_ar :data?.title}</h1>
            <ButtonComponent
             onClick={()=>{handle_share()}}
             tooltip={GetAppText("link_share")}
              title={GetAppText("sharing")}
              svg={
                <SvgComponent
                  name={"ShareSvg"}
                  className={"w-4 h-4"}
                  stroke={"black"}
                  fill={"none"}
                />
              }
              styles={"!py-2 !px-3 !text-black !w-fit !text-F16"}
              type="secondary_transparent"
            />
          </div>
          <div className={`font-normal ${language === "_ar" ?"text-right":"text-left"}  text-F16 
          `}
           dangerouslySetInnerHTML={{ __html:language === "_ar" ?data?.description_ar :data?.description }}>
            {/* {language === "_ar" ?data?.description_ar :data?.description} */}
            </div>
            {/* <div className={`${language === "_ar" ?"text-right":"text-left"} bg-white p-6 flex-row-reverse rounded-xl`} 
           dangerouslySetInnerHTML={{ __html: language === "_ar" ? data?.value_ar:data?.value }}> */}
          <div
            className={`flex  ${
              language === "_ar" ? " flex-row-reverse  space-x-reverse" : ""
            } space-x-3 `}
          >
         {/* { data?.is_urgent===1 &&  <ButtonComponent
              title={GetAppText("urgent")}
              styles={"!py-1.5 !px-3 !h-8 !text-F14 !rounded-[4px] !border-0 !  "}
              type="primary"
            />} */}
<div className={`flex-wrap  ${language === "_ar" ?"justify-end flex-row-reverse":""}  flex gap-3`}>
{tagsName.length>0 && 
tagsName?.map((item,index)=>(
<div key={index} className={`!py-1.5 !px-3 !text-F14  ${item.title === "Urgent" ?"bg-errorColor uppercase text-white":"!text-secondaryTextColor !bg-btn_gray"} !rounded-[4px] !border-0 `}>
{language === "_ar" ?item.title_ar:item.title}
</div>
))
}
</div>
            {/* <ButtonComponent
              title={GetAppText("zakat")}
              styles={
                "!py-1.5 !px-3 !text-F14 !bg-btn_gray !rounded-[4px] !border-0 !text-secondaryTextColor"
              }
              type="primary"
            />
            <ButtonComponent
              title={GetAppText("close_target")}
              styles={
                "!py-1.5 !px-3 !text-F14 !bg-btn_gray !rounded-[4px] !border-0 !text-secondaryTextColor"
              }
              type="primary"
            /> */}
          </div>
          <SeparatorComponent className={"!border-borderSecondaryColor !my-10"} />
          <div
            className={`sm:flex sm:space-y-0 space-y-3  ${
              language === "_ar" ? "flex-row-reverse  sm:space-x-reverse" : ""
            } sm:space-x-3`}
          >
           
        {data?.end_date&&    <div  className="bg-btn_gray w-full rounded-md p-4  md:space-y-3 space-y-2">
                <div
                  className={` flex items-center ${
                    language === "_ar"
                      ? "flex-row-reverse  space-x-reverse"
                      : ""
                  } space-x-2`}
                >
                  <SvgComponent
                    name={"Clock"}
                    className={"w-5 h-5"}
                    stroke={"#999999"}
                    fill={"none"}
                  />
                  <h2 className={`text-secondaryTextColor ${
                    language === "_ar"
                      ? "text-right"
                      : ""
                  } text-F14  font-semibold `}>
                    {GetAppText("remain_time")}
                  </h2>
                </div>
                <h2
                  className={`flex items-center ${
                    language === "_ar"
                      ? "flex-row-reverse  space-x-reverse"
                      : ""
                  } text-black text-F14 font-bold `}
                >
                 {data?.days_left} {GetAppText("day")}
                </h2>
              </div>}
              <div  className="bg-btn_gray w-full rounded-md   p-4  md:space-y-3 space-y-2">
                <div
                  className={` flex  items-center ${
                    language === "_ar"
                      ? "flex-row-reverse  space-x-reverse"
                      : ""
                  } space-x-2`}
                >
                  <SvgComponent
                    name={"Block"}
                    className={"w-5 h-5"}
                    stroke={"#999999"}
                    fill={"none"}
                  />
                  <h2 className={`text-secondaryTextColor ${
                    language === "_ar"
                      ? "text-right"
                      : ""
                  }  text-F14 font-semibold `}>
                    {GetAppText("domain")}{" "}
                  </h2>
                </div>
                <h2
                  className={`flex items-center ${
                    language === "_ar"
                      ? "flex-row-reverse  space-x-reverse"
                      : ""
                  } text-black text-F14 font-bold `}
                >
                  {/* { language === "_ar"?data?.category?.title_ar:data?.category?.title} */}
                  {data?.category? language === "_ar"?data?.category?.title_ar:data?.category?.title:'N/A'}
                </h2>
              </div>
              <div  className="bg-btn_gray w-full rounded-md  p-4  md:space-y-3 space-y-2">
                <div
                  className={` flex items-center ${
                    language === "_ar"
                      ? "flex-row-reverse  space-x-reverse"
                      : ""
                  } space-x-2`}
                >
                  <SvgComponent
                    name={"Donation"}
                    className={"w-5 h-5"}
                    stroke={"#999999"}
                    fill={"none"}
                  />
                  <h2 className={`text-secondaryTextColor ${
                    language === "_ar"
                      ? "text-right"
                      : ""
                  }  text-F14 font-semibold `}>
              {GetAppText("max_limit")}{" "}
                  </h2>
                </div>
                <h2
                  className={`flex items-center space-x-1 ${
                    language === "_ar"
                      ? "flex-row-reverse  space-x-reverse"
                      : ""
                  } text-black text-F14 font-bold `}
                ><div>
                  {settingData?.data[18]?.value?.toLocaleString()}</div> <div> {GetAppText("bhd")}</div>
                </h2>
              </div>
           
          </div>
          <SeparatorComponent className={"!border-borderSecondaryColor !my-10"} />
   {  data?.goal_amount || data?.end_date?     <ProgressBar value={data?.goal_percentage} progressStyle={"!p-1.5"} /> :""}
          <div className={`flex ${language==="_ar"?"":"flex-row-reverse"} justify-between  items-center pb-2`}>
      {  data?.goal_amount ?     <>
            <div>
              <h2 className={`text-black text-F14 ${
                    language === "_ar"
                      ?"text-right" :""} font-bold `}>
                {GetAppText("goal")}
              </h2>
              <h2
              className={`flex items-center space-x-1 ${
                language === "_ar"
                  ? "flex-row-reverse  space-x-reverse"
                  : ""
              } text-secondaryTextColor text-F14  font-semibold `}
              // className="text-secondaryTextColor text-F14 font-semibold "
              >
             <div> {data?.goal_amount?.toLocaleString()} </div> <div> {GetAppText("bhd")}</div>
              </h2>
            </div>

            <div>
              <h2 className={`text-black text-F14 ${
                    language === "_ar"
                      ?"text-right" :""} font-bold `}>
                {GetAppText("residual")}
              </h2>
              <h2
               className={`flex items-center space-x-1 ${
                language === "_ar"
                  ? "flex-row-reverse  space-x-reverse"
                  : ""
              } text-secondaryTextColor text-F14  font-semibold `}
              >
             <div> {data?.remaining_goal_amount?.toLocaleString()}</div><div> {GetAppText("bhd")}</div> 
              </h2>
            </div>
            </>:""}
            <div>
              <h2 className={`text-black text-F14 ${
                    language === "_ar"
                      ?"text-right" :""} font-bold `}>
                {GetAppText("donated")}
              </h2>
              <h2      className={`flex items-center space-x-1 ${
                    language === "_ar"
                      ? "flex-row-reverse  space-x-reverse"
                      : ""
                  } text-secondaryTextColor text-F14  font-semibold `} >
              <div>  {data?.donated_amount?.toLocaleString()}</div> <div> {GetAppText("bhd")}</div>
              </h2>
            </div>
          </div>
      {data?.goal_percentage<100 &&   
      <div className={`flex sm:flex-row flex-col-reverse    ${language === "_ar" ? "sm:flex-row-reverse sm:space-x-reverse" : ""} sm:space-y-0 space-y-reverse space-y-4  sm:space-x-6 pt-5`}>
          <div className=" -lg:w-[55%] -md:w-[75%] w-full">
            <InputComponent 
           type={language === "_ar"?"text":"text"}
              value={language==="_ar"?convertToNumbersInArabic(price):convertToArabicNumbers(price)}
            //  value={language==="_ar"?convertToNumbersInArabic(price):convertToArabicNumbers(price)}
            title="donation_amount"
            // value={price} 
            //  onChange={(e) => setPrice(e.target.value)}
            onChange={handleInputChange}
            />
            </div>
            <div className="flex  items-center gap-2   -lg:w-[45%] -md:w-[35%] -sm:w-[60%] lg:flex-nowrap md:flex-wrap flex-nowrap">
            {/* <ButtonComponent
            onClick={()=>{handleSubmit(data)}}
            loading={addloading}
            disabled={donateloading ||addloading}
              styles={"!text-F18 !w-full  !px-1 !py-2"}
              type="primary"
              title={GetAppText("add_cart")}
            /> */}
            <ButtonComponent
            onClick={()=>{handleDonate(data)}}
            loading={donateloading}
            disabled={donateloading || addloading}
              isSvg
              styles={`!text-F18 ${donateloading?"sm:!px-[28px]":"sm:!px-14"}  sm:!w-fit whitespace-nowrap !w-full  !py-2  `}
              title={GetAppText("donate_now")}
            />
            </div>
           
          </div>}
        </div>
      </div>
      }
{      <div>
        <SeparatorComponent  />
      {similarData.length>0 &&  <div className={"space-y-8 pt-4"}>
          <h1
            className={`font-bold text-F27 text-primary flex ${
              language === "_ar" ? "flex-row-reverse" : ""
            } `}
          >
            {GetAppText("similar_projects")}
          </h1>
          <div
            className={`flex gap-5 flex-wrap md:justify-normal justify-center ${language === "_ar" ? "flex-row-reverse" : ""} `}
          >
         {loading?    <div className="flex justify-center items-center h-[440px]">
      <Loader/>
      </div>:similarData.length>0 ?
      similarData?.map((item,index)=>(
           <DonationCard  data={item} onClick={()=>{handleProjectClick(item)}} onImageClick={()=>{ImageClick(item)}}  onCartClick={()=>{handleCartDetails(item)}}   key={index}/>
         )):
         (!loading && <div className="flex w-full h-[350px]  justify-center text-F20 font-semibold items-center">
          {GetAppText("no_data")}
           </div>)
           }
          </div>
        </div>
}
      </div>}
     

    </div>
    {/* {isAddModal&& <DonationModal isAddModal={isAddModal} details={details} setIsAddModal={setIsAddModal}/>} */}
    </>
  );
};

export default ProjectDetails;
