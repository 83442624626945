import React, { useEffect, useState } from "react";
import { GetAppText } from "../../../../../utils";
import { useSelector } from "react-redux";
import { apiRoutes } from "../../../../../apis/routes";
import { getRequest } from "../../../../../apis/methods";
import { Loader } from "../../../../components/Loader";
import Pagination from "../../../../components/Pagination";
import { ButtonComponent } from "../../../../components/ButtonComponent";

export const DonationRecord = () => {
  const language = useSelector((state) => state.language.value);
  const [loading,setLoading]=useState(false)
  const textAlignStyle = language === "_ar" ? "right" : "left";
  const [donationData,setDonationData]=useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState('');
  const [showPage, setshowPage] = useState(1);
const [showLoading,setShowLoading]=useState(false)
useEffect(()=>{
  fetchData()
},[currentPage])
  const table_data = [
    {
      heading: GetAppText("donation_date"),
      values: ["2024-07-01", "2024-07-01", "2024-07-01", "2024-07-01"],
    },
    {
      heading: GetAppText("project_name"),
      values: [
        "المساعدة الطبية للأطفال",
        "التعليم للجميع",
        "التعليم للجميع	",
        "التعليم للجميع	",
      ],
    },
    {
      heading: GetAppText("donation_amount"),
      values: ["20.0 د.ب", "20.0 د.ب", "20.0 د.ب", "20.0 د.ب"],
    },
    {
      heading: GetAppText("project_status"),
      values: [
        GetAppText("complete"),
        GetAppText("pending"),
        GetAppText("complete"),
        GetAppText("pending"),
      ],
    },
    {
      heading: GetAppText("transaction_number"),
      values: ["TRX123456789", "TRX123456789", "TRX123456789", "TRX123456789"],
    },
  ];
  const renderValue = (value) => {
    if (value === "COMPLETED") {
      return (
        <div className="border w-fit border-successColor text-center px-2 py-1 rounded-xl bg-statusGreenBorder">
          <span className="text-successColor">{GetAppText("complete")}</span>
        </div>
      );
    } else if (value === "PENDING") {
      return (
        <div className="border w-fit border-secondary text-center px-2 py-1 rounded-xl bg-statusOrangeBorder">
          <span className="text-secondary">{GetAppText("pending")}</span>
        </div>
      );
    }
    else if (value === "FAILED") {
      return (
        <div className="border w-fit border-red-600 text-center px-2 py-1 rounded-xl bg-red-50">
          <span className="text-red-600">{GetAppText("failed")}</span>
        </div>
      );
    }
    else if (value === "CANCEL") {
      return (
        <div className="border w-fit border-gray-500 text-center px-2 py-1 rounded-xl bg-slate-50">
          <span className="text-gray-500">{GetAppText("cancel")}</span>
        </div>
      );
    }
    else if (value === "ERROR") {
      return (
        <div className="border w-fit border-red-500 text-center px-2 py-1 rounded-xl bg-red-50">
          <span className="text-red-500">{GetAppText("error")}</span>
        </div>
      );
    }
    
    
    
    else {
      return value;
    }
  };
  const headers = ["donation_date", "project_name", "donation_amount", "project_status", "transaction_number"];
  const fetchData = async () => {
    try {
      setLoading(true)
      const response = await getRequest(`${apiRoutes.get_donation}?page=${currentPage}`); // Replace with your endpoint

      if (response.status==="success") {
    
        const responseData=response?.data?.data
        const transformedData = responseData?.map((item) => ({
          date: item.created_at,
          name: item?.itemable?.title || 'N/A',  // Defaults to 'N/A' if name is unavailable
          name_ar: item?.itemable?.title_ar || 'N/A',  // Defaults to 'N/A' if name is unavailable
          donationAmount: item?.price,
          status: item?.order?.transaction?.status,
          transactionId: item?.order?.reference || 'N/A'  // Defaults to 'N/A' if transaction ID is unavailable
      }));
      setDonationData(transformedData)
      setLastPage(response?.data?.last_page)
        // dispatch(infoHandler(response?.data));
      }
    } catch (error) {
      console.error("Error fetching device info:", error);
    }
    setLoading(false)
  };

 const fetchShowMoreData = async (page) => {
            setShowLoading(true);

          
            try {
              const response = await getRequest(`${apiRoutes.get_donation}?page=${page}`);
              if (response.status === "success") {
                setShowLoading(false);
                setshowPage(showPage + 1); 
                const responseData=response?.data?.data
                const transformedData = responseData?.map((item) => ({
                  date: item.created_at,
                  name: item?.itemable?.title || 'N/A',  // Defaults to 'N/A' if name is unavailable
                  name_ar: item?.itemable?.title_ar || 'N/A',  // Defaults to 'N/A' if name is unavailable
                  donationAmount: item?.price,
                  status: item?.order?.transaction?.status,
                  transactionId: item?.order?.reference || 'N/A'  // Defaults to 'N/A' if transaction ID is unavailable
              }));
              // setDonationData(transformedData)


                // Append the new data to the existing data
                setDonationData((prevData) => [...prevData, ...transformedData]);
                setLastPage(response?.data?.last_page);
             
              } else {
                setShowLoading(false);
              }
            } catch (error) {
                setShowLoading(false);
              console.error("Error fetching data:", error);
            }
          };


  const handlePageChange = (page) => {
 
    setCurrentPage(page);
  };
  
  const handleShowMore = () => {
    if (showPage < lastPage) {
       
      const page=showPage + 1
      fetchShowMoreData(page)
      // Increment the current page
    }
  };

  const getDateOnly = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  return (
    <div className="w-full h-full sm:space-y-6 space-y-4">
      <h1
        className={`text-primary text-F24 font-bold ${
          language === "_ar" ? "text-right" : "text-left"
        }`}
      >
        {GetAppText("donation_record")}
      </h1>
      <div className="bg-white rounded-xl w-full h-[500px] overflow-y-auto">
        <table
          dir={language === "_ar" ? "rtl" : "ltr"}
          className="w-full "
        >
          {/* <thead>
            <tr>
              {table_data.map((data, index) => (
                <th
                  key={index}
                  className="text-left md:text-F14 text-F12 border-b border-borderSecondaryColor text-secondaryTextColor font-normal lg:px-6 px-2 py-5"
                  style={{ textAlign: textAlignStyle }}
                >
                  {data.heading}
                </th>
              ))}
            </tr>
          </thead> */}
          <thead>
    <tr>
      {headers?.map((header, index) => (
        <th
          key={index}
          className="text-left bg-white md:text-F14 text-F12 border-b top-0 z-10 divide-y mb-2 sticky  divide-borderSecondaryColor border-borderSecondaryColor text-secondaryTextColor font-normal lg:px-6 px-2 py-5"
          style={{ textAlign: textAlignStyle }}
        >
          {GetAppText(header)}
        </th>
      ))}
    </tr>
  </thead>
          {/* <tbody>
            {table_data[0]?.values.map((_, rowIndex) => (
              <tr key={rowIndex}>
                {table_data.map((data, colIndex) => (
                  <td
                    key={colIndex}
                    className="text-F12 lg:p-6 px-2 py-5"
                    style={{ textAlign: textAlignStyle }}
                  >
                    {renderValue(data.values[rowIndex])}
                  </td>
                ))}
              </tr>
            ))}
          </tbody> */}
         {  loading?
          <tbody>
          <tr>
            <td colSpan="5" className="py-28 text-secondaryTextColor  text-center text-F18 font-semibold">
              <Loader/>
            </td>
          </tr>
        </tbody>
         :
         
         donationData?.length>0? <tbody>
    {donationData&&donationData?.map((item, rowIndex) => (
      <tr key={rowIndex}>
        <td className="text-F12 lg:p-6 px-2 py-5" style={{ textAlign: textAlignStyle }}>
          {getDateOnly(item.date)}
        </td>
        <td className="text-F12 lg:p-6 px-2 py-5 underline" style={{ textAlign: textAlignStyle }}>
          {language==="_ar"?item?.name_ar :item.name}
        </td>
        <td className="text-F12 lg:p-6 px-2 py-5" style={{ textAlign: textAlignStyle }}>
          {item.donationAmount} {GetAppText("bhd")}
        </td>
        <td className="text-F12 lg:p-6 px-2 py-5" style={{ textAlign: textAlignStyle }}>
          {renderValue(item.status)}
        </td>
        <td className="text-F12 lg:p-6 px-2 py-5 underline" style={{ textAlign: language === "_ar" ? "center" : "left" }}>
          {item.transactionId}
        </td>
      </tr>
    ))}
  </tbody>
  :
  <tbody>
              <tr>
                <td colSpan="5" className="py-32 text-secondaryTextColor  text-center text-F18 font-semibold">
                  {GetAppText("no_data")}
                </td>
              </tr>
            </tbody>}
        </table>
      </div>
      <div className="sm:flex hidden justify-center w-full ">
          <Pagination currentPage={currentPage} lastPage={lastPage} onPageChange={handlePageChange} />
         
          </div>
                 {showPage!==lastPage &&  <div className="sm:hidden flex justify-center font-medium text-F18 w-full  pt-2">
                    <ButtonComponent disabled={showLoading || showPage===lastPage} loading={showLoading}  onClick={handleShowMore} styles={"!w-full !py-2.5  !border-2 !text-lightTextColor !border-borderColor"}  title={GetAppText("show_more")} type="transparent"/>
                   
                    </div>}
    </div>
  );
};
