import React from "react";
import { GetAppText } from "../../../utils";
import { useSelector } from "react-redux";
import SvgComponent from "../SvgComponent";
export const InputComponent = React.forwardRef(({
  svg = false,
  title = "",
  input_style,
  isTranslate = true,
  type = "text",
  value,
  onChange,
  name,disabled,
  toggleVisibility, // Function to toggle password visibility
  isPasswordVisible,
  maxLength, // State to determine if password is visible
onKeyDown
} , ref) => {
  const language = useSelector((state) => state.language.value);
  return (
    <div className="w-full">
      {/* <label
      htmlFor="default-search"
      className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
    >
      Search
    </label> */}
      <div className="relative">
        {svg && (
          <div
            className={`absolute inset-y-0 flex items-center pointer-events-none ${
              language === "_ar" ? "end-0 pe-3" : "start-0 ps-3"
            }`}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                stroke="#727272"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
        <input
        disabled={disabled}
        maxLength={maxLength}
        ref={ref}
        onKeyDown={onKeyDown}
          type={type}
          id={name}
          value={value}
          onChange={onChange}
          className={`block w-full  py-3 placeholder:text-F14 text-sm placeholder:text-lightTextColor focus:outline-none focus:border-borderColor focus:ring-0 border-2 border-borderColor rounded-lg ${
            language === "_ar" ? "pe-2 text-right" : "ps-3"
          } ${input_style}`}
          placeholder={
            title && isTranslate ? `${GetAppText(title)}  ${ language === "_ar"?`(د.ب)`:""}` : `${title}`
          }
          name={name}
        />
        {name === "password" || name === "confirm_password" ? (
          <div
            className={`absolute inset-y-0 flex items-center ${
              language === "_ar" ? "start-0 ps-3" : "end-0 pe-3"
            }`}
            onClick={toggleVisibility}
            style={{ cursor: "pointer" }}
          >
            {isPasswordVisible ? (
              <SvgComponent
                name={"Eye"}
                className={"w-6 h-5"}
                stroke={"#AFAFAF"}
                fill={"none"}
              />
            ) : (
              <SvgComponent
                name={"EyeCross"}
                className={"w-6 h-6"}
                stroke={"#AFAFAF"}
                fill={"none"}
              />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
});
