import React, { useRef } from "react";
import { ButtonComponent } from "../../../components/ButtonComponent";
import SvgComponent from "../../../components/SvgComponent";
import { GetAppText, StyleClass } from "../../../../utils";
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Texture4 } from "../../../../assets/svgs/texture4.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation,Autoplay  } from "swiper/modules";
import { modalHandler } from "../../../../store/slices/modalSlice";
import "swiper/css";
import "swiper/css/navigation";
import { Loader } from "../../../components/Loader";
import { Image_URL } from "../../../../utils/BaseUrl";
export const AboutDonations = ({data,loading}) => {
  const language = useSelector((state) => state.language.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();
const handleClick=()=>{
 
    navigate(`/stories-listing`);
 
}
const swiperRef = useRef();

// const data=[
//   {id:1,name:"Title 1",subName:""},
//   {id:2,name:"Title 2",subName:""},
//   {id:3,name:"Title 3",subName:""},
// ]

const handleProjectClick = (id) => {

  navigate(`/story/${id}`);
};

const handle_share=(item)=>{
  const currentURL = window.location.href;

const fullLink=`${currentURL}story/${item?.slug}`
    // Copy the link to the clipboard
    navigator.clipboard.writeText(fullLink).then(() => {
      dispatch(modalHandler({type:"share",msg:GetAppText("link")}));
      setTimeout(()=>{
        dispatch(modalHandler(""));
      },100)
    });

}

  return (
    <div className="w-full h-full">
      
     { loading ?
         <div className="flex h-[400px] w-full justify-center items-center">
         <Loader/>
         </div>
         : 
          <Swiper
          // spaceBetween={30}
          slidesPerView={1}
          loop={true}
          allowTouchMove={false}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          modules={[Navigation, Autoplay]} // Include Autoplay
          // autoplay={{
          //   delay: 2500, // Set the delay in milliseconds (3 seconds in this case)
          //   disableOnInteraction: false, // Allows autoplay to continue after interaction
          // }}
     
          className="w-full h-full relative"
        >
          {
 loading ?
  <div className="flex  w-full justify-center h-[300px] items-center">
  <Loader/>
  </div>
  : data?.length>0 && data?.map((item,index)=>(
    
            <SwiperSlide key={index} >

    <div  className={` md:flex-row  flex-col flex justify-center ${
      language === "_ar" ? "md:flex-row-reverse" : ""
    } `}>
       
<div className="md:w-6/12 relative bg-primary">
<div className="absolute top-0 left-0">
<Texture4 className=""/>
</div>
  <div className="lg:p-16 pb-8 sm:p-10 p-8 h-full  flex flex-col justify-between">
    <div className="space-y-4 relative">
    <div className="sm:mb-16 mb-28">
    <div
      className={`flex flex-col-reverse sm:flex-row  ${language==="_ar"?"items-end sm:flex-row-reverse":" items-start"} sm:justify-between absolute z-20 w-full sm:items-center ${StyleClass()}`}
    >
      <h1 className={`text-white ${language === "_ar" ? "text-right" : "text-left"} sm:mt-0 mt-6 font-bold text-F20`}>
        {/* {GetAppText("hope_grows")} */}
        {language === "_ar" ?item?.title_ar:item?.title}
      </h1>
      <ButtonComponent
      title={GetAppText("sharing")}
      tooltip={GetAppText("link_share")}
      onClick={()=>{handle_share(item)}}
      svg={
        <SvgComponent
          name={"ShareSvg"}
          className={"w-4 h-4"}
          stroke={"white"}
          fill={"none"}
        />
      }
      styles={"!py-2 !px-3 !w-fit !text-F16"}
      type="transparent"
    />
    </div>
</div>
      <h1 className={`text-white ${language === "_ar" ? "text-right" : "text-left"} text-F16 font-light line-clamp-4 tracking-wide leading-6`}>
      {language === "_ar" ?item?.short_description_ar:item?.short_description}
      </h1>
      </div>
 
      <div
        className={`sm:flex justify-between pt-5 relative items-center ${StyleClass()}`}
      >
      <div className={`sm:flex  sm:items-center  flex-wrap sm:space-y-0 space-y-3  sm:gap-3 ${StyleClass()}`}>
          <ButtonComponent
            title={GetAppText("read_more")}
            onClick={()=>handleProjectClick(item?.slug)}
            type="secondary"
        styles={`!space-x-2 sm:!w-fit !w-full ${language === "_ar" ? "" : "!space-x-reverse"}`}
            svg={
              <SvgComponent
                name={language === "_ar" ? "ArrowLeft" : "ArrowRight"}
                className={"w-5 h-5"}
                fill={"none"}
                stroke={"white"}
              />
            }
          />

          <ButtonComponent
            title={GetAppText("all_stories")}
            type="transparent"
          styles={'sm:!w-fit !w-full'}
            onClick={handleClick}
          />
        </div>
        <div className={`sm:flex hidden flex-row  ${language === "_ar" ? "left-0" : "right-0"} items-center space-x-2`}>
          <div  onClick={() => swiperRef.current?.slidePrev()} className="flex cursor-pointer justify-center items-center p-2 border-2 border-white rounded-full">
            <SvgComponent
              name={"ArrowLeft"}
              className={"w-6 h-6"}
              stroke={"white"}
              fill={"none"}
            />
          </div>
          <div  onClick={() => swiperRef.current?.slideNext()} className="flex cursor-pointer justify-center items-center p-2 border-2 border-white rounded-full">
            <SvgComponent
              name={"ArrowRight"}
              className={"w-6 h-6"}
              stroke={"white"}
              fill={"none"}
            />
          </div>
        </div>
      
      </div>
  
  </div>
</div>
<div className="md:w-6/12 sm:h-[428px] h-[230px] relative sm:bg-transparent bg-primary flex sm:p-0 p-8 sm:pt-0 pt-0">
<img

 className="w-full object-cover sm:h-full   sm:rounded-none rounded-2xl"
 alt="logo"
 src={`${Image_URL}${item?.image}`}

/>

{/* { item?.media?.map((item,index)=>(
 (index===0 && <img
 key={index}
 className="w-full object-cover"
 alt="logo"
 src={item?.thumbnail}
/>)
))} */}
 
</div>

</div>


   
      </SwiperSlide>
      
         
        ))
        // :(!loading && <div className="flex w-full h-[300px] justify-center text-F20 font-semibold items-center">
        //   {GetAppText("no_data")}
        //    </div>)
}
<div  className={`sm:hidden block  
        `}>
       <div className="flex items-center left-4 space-x-2.5 absolute bottom-28 z-50  ">
         <div
           className="border-2 border-white p-2 rounded-full cursor-pointer"
           onClick={() => swiperRef.current?.slidePrev()}
         >
           <SvgComponent
             name="ArrowLeft"
             stroke={"white"}
             fill={"none"}
             className="md:w-6 md:h-6 h-5 w-5"
           />
         </div>
        
       </div>
       <div className="flex items-center right-4 space-x-2.5 absolute bottom-28  z-50  ">
       <div
           className="border-2 border-white p-2 rounded-full cursor-pointer"
           onClick={() => swiperRef.current?.slideNext()}
         >
           <SvgComponent
             name="ArrowRight"
             stroke={"white"}
             fill={"none"}
             className="md:w-6 md:h-6 h-5 w-5"
           />
         </div>
         </div>
   
         </div>
      </Swiper>}
    </div>
  );
};
