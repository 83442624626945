import React, { useEffect, useState, useCallback } from "react";
import { DonationCard } from "../../components/DonationCard";
import { GetAppText } from "../../../utils";
import { useSelector, useDispatch } from "react-redux";
import { ButtonComponent } from "../../components/ButtonComponent";
import { useLocation, useNavigate } from "react-router-dom";
import Dropdown from "../../components/Dropdown";

import SvgComponent from "../../components/SvgComponent";
import { SeparatorComponent } from "../../components/Separator";
import { getRequest } from "../../../apis/methods";
import { apiRoutes } from "../../../apis/routes";
import { Loader } from "../../components/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { modalHandler } from "../../../store/slices/modalSlice";
import DonationModal from "../../components/DonationModal";
import { handleClick, handleSuccess } from "../../components/Toast/toastHelper";

import debounce from "lodash.debounce";
import Pagination from "../../components/Pagination";
const DonationProjects = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen4, setIsDropdownOpen4] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const [isAddModal, setIsAddModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedValue2, setSelectedValue2] = useState("");
  const [selectedValueCondition, setSelectedValueCondition] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [selectedGovernet, setSelectedGovernet] = useState(
    {id: 0,         
    image: "",
    is_active: 1,
    slug: "all-1",
    name: "All",
    name_ar: "الكل"
}
    // {
    // id: 1,
    // name: "The capital",
    // name_ar: "\u0627\ufedf\ufecc\ufe8e\ufebb\ufee4\ufe94",
    // is_active: true,
  // }
);
  const [data, setData] = useState("");
  const [details, setDetails] = useState("");
  const [category, setCategory] = useState("");
  const [isButton, setIsButton] = useState(false);
  const [type, setType] = useState("");
  const [sort, setSort] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [categoryParams, setCategoryParams] = useState("");
  const [statusParam, setStatusParam] = useState([]);
  // const [statusParam, setStatusParam] = useState("");
  const [sortParam, setSortParam] = useState("");
  const [typeSelect, setTypeSelect] = useState("Projects");
  const navigate = useNavigate();
  const [selectedAreaParam, setSelectedAreaParam] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});
  const language = useSelector((state) => state.language.value);
  const isOpen = useSelector((state) => state.openModel.value);
  const info = useSelector((state) => state.info.value);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState("");
  const [areas, setAreas] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [showPage, setshowPage] = useState(1);
  const [showLoading,setShowLoading]=useState(false)
  // const lastPage = 5;
  // Example last page number
  // Debounce the fetchData function

  const fetchData = async () => {
    setLoading(true);
  setshowPage(1)
    let data = {};
    if (searchParam) {
      data["search"] = searchParam;
    }
    if (typeSelect === "Projects" && categoryParams) {
      data["category_ids"] = categoryParams;
    }
    if (typeSelect === "Projects" && statusParam.length > 0) {
      data["tag_ids"] = statusParam;
    }
    if (typeSelect === "Mosques" && selectedAreaParam.length > 0) {
      data["area_ids"] = selectedAreaParam;
    }
    if (typeSelect === "Mosques") {
      data["governorate_ids"] = selectedGovernet;
    }
    if (typeSelect === "Projects" && sortParam) {
      data["sort_by"] = sortParam;
    }
    if (currentPage) {
      data["page"] = currentPage;
    
      
    }
    
    try {
      const response = await getRequest(
        `${
          typeSelect === "Projects"
            ? apiRoutes.get_projects
            : apiRoutes.get_mosques
        }`,
        data
      );
      if (response.status === "success") {
        setData(response?.data?.data);
        setCategory(response?.data?.categories);
      //   setCategory([
      //     {id: 100,
            
      //         image: "images/categories/LrWmOyGMf4ihKrKKc20eikO9R3QGgpdMJ4ltm3oH.svg",
      //         is_active: 1,
      //         slug: "all-1",
      //         title: "All",
      //         title_ar: "الكل"
      //     },
      //     ...response?.data?.categories // Keeping existing categories
      // ]);
        setType(response?.data?.types);
        setSort(response?.data?.sortBy);
  
        setLastPage(response?.data?.last_page);
        // Set other data fields as needed
       
      }
    } catch (error) {
    
    } finally {
      setLoading(false);
    }
  };
  


  const fetchShowMoreData = async (page) => {
    setShowLoading(true);
  
    let data = {};

    if (page) {
      data["page"] = page;
    
    
    }
    
    try {
      const response = await getRequest(
        `${
          typeSelect === "Projects"
            ? apiRoutes.get_projects
            : apiRoutes.get_mosques
        }`,
        data
      );
      if (response.status === "success") {
        setData((prevData) => [...prevData, ...response?.data?.data]);
        // setData(response?.data?.data);
        setCategory(response?.data?.categories);
        setType(response?.data?.types);
        setSort(response?.data?.sortBy);
        setshowPage(showPage + 1); 
        setLastPage(response?.data?.last_page);
        // Set other data fields as needed
       
      }
      setShowLoading(false);
    } catch (error) {
      
    } finally {
      setShowLoading(false);
    }
  };
  const handleShowMore = () => {
    
    if (showPage < lastPage) {
       
      const page=showPage + 1
      fetchShowMoreData(page)
      // Increment the current page
    }
  };

  const debouncedFetchData = useCallback(
    debounce(fetchData, 300), // Delay of 300ms
    [
      searchParam,
      categoryParams,
      statusParam,
      sortParam,
      typeSelect,
      currentPage,
      selectedAreaParam,
      selectedGovernet,
    ]
  );
  
  // Initial fetch without delay
  useEffect(() => {
    fetchData(); // Instant fetch on initial load
    setInitialLoad(false);
  }, []);
  
  // Debounced fetch for parameter changes
  useEffect(() => {
    if (!initialLoad) {
      debouncedFetchData();
    }
    return debouncedFetchData.cancel; // Cleanup on unmount
  }, [
    searchParam,
    categoryParams,
    statusParam,
    sortParam,
    typeSelect,
    currentPage,
    selectedAreaParam,
    selectedGovernet,
  ]);
  

  // Call the debounced function whenever the search param or other parameters change
  // useEffect(() => {
  //   debouncedFetchData();
  //   return debouncedFetchData.cancel; // Cleanup on unmount
  // }, [
  //   searchParam,
  //   categoryParams,
  //   statusParam,
  //   sortParam,
  //   debouncedFetchData,
  //   currentPage,
  //   selectedAreaParam,
  // ]);

  useEffect(() => {
    if (isOpen?.type === "toast") {
      handleSuccess(isOpen?.msg, language);
    } else if (isOpen?.type === "toastError") {
      handleClick(isOpen?.msg, language);
    } else if (isOpen === "priceError") {
      handleClick(GetAppText("req_price"), language);
    }
  }, [isOpen]);
  const location = useLocation();


  useEffect(() => {
    if(selectedGovernet?.id!==0)
   { getArea();}
  }, [selectedGovernet]);

  const getArea = async () => {
    let data = {};
    if (selectedGovernet) {
      data["governorate_id "] = selectedGovernet?.id;
    }
    try {
      const response = await getRequest(`${apiRoutes.get_areas}`, data); // Replace with your endpoint

      if (response.status === "success") {
     
        setAreas(response?.data);
        // dispatch(infoHandler(response?.data));
      }
    } catch (error) {
      console.error("Error fetching device info:", error);
    }
  };

  const handler = () => {

    setIsDropdownOpen(!isDropdownOpen);
  };
  const handler4 = () => {

    setIsDropdownOpen4(!isDropdownOpen4);
  };
  const handler2 = () => {

    setIsDropdownOpen2(!isDropdownOpen2);
  };
  const handler3 = () => {

    setIsDropdownOpen3(!isDropdownOpen);
  };

  const getQueryParams = () => new URLSearchParams(location.search);
  const handleProjectClick = (val) => {
    setDetails(val);

    setIsAddModal(true);
    dispatch(modalHandler("isDonate"));
  };
  const updateQueryParams = (newParams) => {
    const params = getQueryParams();
    Object.keys(newParams).forEach((key) => {
      params.set(key, newParams[key]);
    });
    return params.toString();
  };

  const handleSelect = (value) => {
    // const newParams = updateQueryParams({ type: value });
    // navigate(`?${newParams}`);
   
    setStatusParam(value?.value);
    if (language === "_ar") {
      setSelectedValue(value?.key_ar);
    } else {
      setSelectedValue(value?.key);
    }
    setIsDropdownOpen(false);
  };
  const [selectedValues, setSelectedValues] = useState([ {id: 0,
            
    image: "",
    is_active: 1,
    slug: "all-1",
    title: "All",
    title_ar: "الكل"
},]);
  const [selectedAreaValues, setSelectedAreaValues] = useState([]);
  const [selectedStatusValues, setSelectedStatusValues] = useState([]);


  const handleSelectCondition = (value) => {
    // Update selectedValues with selected/unselected options
 
    // setSelectedValues(value)
    // setCategoryParams(value?.id)
    setSelectedValues((prevValues) => {
      // Check if the option is already selected
      const isSelected = prevValues.some((val) => val.id === value.id);
  
      if (isSelected) {
        // Deselect: Clear the selection
        setCategoryParams(null);
        return [];
      } else {
        // Select: Set the option
        setCategoryParams(value?.id);
        return [value];
      }
    });
    
    // setSelectedValues((prevSelected) => {
    //   const isSelected = prevSelected.some((item) => item.id === value.id);

    //   // Update selected IDs array
    //   setCategoryParams((prevIds) => {
    //     if (isSelected) {
    //       // Remove unselected option's ID from categoryParams array
    //       return prevIds.filter((id) => id !== value.id);
    //     } else {
    //       // Add selected option's ID to categoryParams array
    //       return [...prevIds, value.id];
    //     }
    //   });

    //   // Return updated selected values
    //   if (isSelected) {
    //     // Unselect the option
    //     return prevSelected.filter((item) => item.id !== value.id);
    //   } else {
    //     // Select the option
    //     return [...prevSelected, value];
    //   }
    // });

 
  };

  const handleSelectArea = (value) => {
    // Update selectedValues with selected/unselected options
    setSelectedAreaValues((prevSelected) => {
      const isSelected = prevSelected.some((item) => item.id === value.id);

      // Update selected IDs array
      setSelectedAreaParam((prevIds) => {
        if (isSelected) {
          // Remove unselected option's ID from categoryParams array
          return prevIds.filter((id) => id !== value.id);
        } else {
          // Add selected option's ID to categoryParams array
          return [...prevIds, value.id];
        }
      });

      // Return updated selected values
      if (isSelected) {
        // Unselect the option
        return prevSelected.filter((item) => item.id !== value.id);
      } else {
        // Select the option
        return [...prevSelected, value];
      }
    });

  };

  const handleSelectStatus = (value) => {
    // Update selectedValues with selected/unselected options
  
    setSelectedStatusValues((prevSelected) => {
      const isSelected = prevSelected.some((item) => item.id === value.id);

      // Update selected IDs array
      setStatusParam((prevIds) => {
        if (isSelected) {
          // Remove unselected option's ID from categoryParams array
          return prevIds.filter((id) => id !== value.id);
        } else {
          // Add selected option's ID to categoryParams array
          return [...prevIds, value.id];
        }
      });

      // Return updated selected values
      if (isSelected) {
        // Unselect the option
        return prevSelected.filter((item) => item.id !== value.id);
      } else {
        // Select the option
        return [...prevSelected, value];
      }
    });

  };
  const handleSelectGovernet = (value) => {
    // Update selectedValues with selected/unselected options
    // setSelectedGovernet(value);
    // setSelectedAreaParam([]);
      setSelectedGovernet((prevValue) => {
    // Check if the option is already selected
    const isSelected = prevValue?.id === value.id;

    if (isSelected) {
      // Deselect: Clear the selection
      setSelectedAreaParam([]);
      return null;
    } else {
      // Select: Set the new option
      setSelectedAreaParam([]);
      return value;
    }
  });



    
    // setIsDropdownOpen4(false)
  };

  const handleSelect2 = (value) => {

    setSortParam(value?.type);
    if (language === "_ar") {
      setSelectedValue2(value?.name_ar);
    } else {
      setSelectedValue2(value?.name);
    }

    setIsDropdownOpen3(false);
  };

  const handleCartDetails = (items) => {
   
    setDetails(items);
    setIsAddModal(true);
    dispatch(modalHandler(""));
  };

  const handleSelectType = (item) => {
    if (item === "Projects") {
      setTypeSelect("Projects");
    } else {
      setTypeSelect("Mosques");
    }
  };

  const ImageClick = (val) => {

    if (typeSelect === "Projects") {
      navigate(`/donation-project/${val?.slug}`);
    } else {
      navigate(`/donation-mosque/${val?.slug}`);
    }
  };

  const handlePageChange = (page) => {
  
    setCurrentPage(page);
  };

  return (
    <>
      <div
        className={`flex ${
          language === "_ar" ? "flex-row-reverse " : ""
        } md:px-20 px-5 md:py-16 py-8 w-full`}
      >
        <div className="space-y-6 w-full">
          <ToastContainer />
          <h1
            className={`flex ${
              language === "_ar" ? "flex-row-reverse " : ""
            } font-bold text-F24`}
          >
            {GetAppText("donationProjects")}
          </h1>
          {/*Page header*/}
          <div
            className={`sm:flex items-center ${
              language === "_ar" ? "sm:flex-row-reverse sm:space-x-reverse" : ""
            } sm:space-x-4 md:space-y-0 space-y-6 w-full`}
          >
            <div className="lg:w-4/12  w-full">
              {/* <label
                htmlFor="default-search"
                className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
              >
                Search
              </label> */}
              <div className="relative">
                <div
                  className={`absolute inset-y-0 flex items-center pointer-events-none 
                    ${language === "_ar" ? " end-0  pe-3" : "start-0 ps-3"}`}
                >
                  <SvgComponent
                    name={"Search"}
                    className={`w-4 h-4`}
                    stroke={"#727272"}
                    fill={"none"}
                  />
                </div>
                <input
                  type="text"
                  value={searchParam}
                  onChange={(e) => setSearchParam(e.target.value)}
                  id="default-search"
                  className={`block w-full p-2 py-3 placeholder:text-F12 text-sm placeholder:text-lightTextColor focus:outline-none focus:border-borderColor focus:ring-0 border-2 border-borderColor rounded-lg ${
                    language === "_ar" ? "pe-8 text-right" : "ps-8"
                  }`}
                  placeholder={GetAppText("search_here")}
                />
              </div>
            </div>

            <div
              className="bg-borderColor sm:block hidden h-10"
              style={{ width: "1.5px" }}
            ></div>
            <div
              className={`flex   sm:flex-wrap gap-3    md:items-end items-start ${
                language === "_ar"
                  ? `${typeSelect === "Mosques" && "lg:flex-row-reverse "}`
                  : `${typeSelect === "Projects" ? "lg:flex-row-reverse ":""}`
              }  md:justify-between ${ language === "_ar"?" justify-end":""}  md:w-full `}
            >
              {typeSelect === "Projects" && (
                <div
                  className={`md:flex  lg:space-y-0 md:space-y-1 items-center ${
                    language === "_ar"
                      ? "flex-row-reverse md:space-x-reverse"
                      : "flex-row"
                  }    md:gap-3 `}
                >
                  <div className="text-F16 text-primary md:whitespace-nowrap md:block hidden">
                    {GetAppText("sort")}
                  </div>
                  <div>
                    <ButtonComponent
                      onClick={handler3}
                      title={
                        selectedValue2 === ""
                          ? GetAppText("closest_complete")
                          : selectedValue2
                      }
                      svg={
                        <SvgComponent
                          name={"ChevronDown"}
                          className={`w-2.5 h-2`}
                          stroke={"#1B1C1E"}
                          fill={"none"}
                        />
                      }
                      styles={
                        "py-2 sm:px-4 px-1 sm:!text-F16 !whitespace-nowrap !text-F13 !normal-case"
                      }
                      type="secondary_transparent"
                    />
                    <Dropdown
                      mainStyle={`sm:!w-40 !w-36 !h-36`}
                      sort={true}
                      handleSelect={handleSelect2}
                      isOpen={isDropdownOpen3}
                      options={info?.sorts}
                      setIsDropdownOpen={setIsDropdownOpen3}
                    />
                  </div>
                </div>
              )}
               {/* <div className="sm:hidden flex flex-wrap gap-3 ">
                <ButtonComponent
                  onClick={() => handleSelectType("Mosques")}
                  title={GetAppText("masjid")}
                  svg={
                    <SvgComponent
                      name={
                        typeSelect === "Mosques"
                          ? "CheckboxFill"
                          : "CircleCheckboxUnfill"
                      }
                      className={`w-6 h-6`}
                      fill={"none"}
                    />
                  }
                  styles={
                    "py-2 sm:px-4 px-1 sm:text-F16 text-F14 whitespace-nowrap flex  flex-row-reverse gap-2 "
                  }
                  type="secondary_transparent"
                />
                <ButtonComponent
                  onClick={() => handleSelectType("Projects")}
                  title={GetAppText("projects")}
                  svg={
                    <SvgComponent
                      name={
                        typeSelect === "Projects"
                          ? "CheckboxFill"
                          : "CircleCheckboxUnfill"
                      }
                      className={`w-6 h-6`}
                      fill={"none"}
                    />
                  }
                  styles={
                    "py-2 sm:px-4 px-1 sm:text-F16 text-F14 whitespace-nowrap flex  flex-row-reverse gap-2 "
                  }
                  type="secondary_transparent"
                />
                </div> */}
              <div
                className={`flex   flex-wrap   gap-3  ${
                  language === "_ar" ? "justify-end flex-row-reverse" : ""
                }    `}
              >
      
     
                <div className="sm:flex hidden  flex-wrap gap-3 ">
                <ButtonComponent
                  onClick={() => handleSelectType("Projects")}
                  title={GetAppText("projects")}
                  svg={
                    <SvgComponent
                      name={
                        typeSelect === "Projects"
                          ? "CheckboxFill"
                          : "CircleCheckboxUnfill"
                      }
                      className={`w-6 h-6`}
                      fill={"none"}
                    />
                  }
                  styles={
                    "py-2 sm:px-4 px-1 sm:!text-F16 !normal-case !text-F14 whitespace-nowrap flex  flex-row-reverse gap-2 "
                  }
                  type="secondary_transparent"
                />
                <ButtonComponent
                  onClick={() => handleSelectType("Mosques")}
                  title={GetAppText("masjid")}
                  svg={
                    <SvgComponent
                      name={
                        typeSelect === "Mosques"
                          ? "CheckboxFill"
                          : "CircleCheckboxUnfill"
                      }
                      className={`w-6 h-6`}
                      fill={"none"}
                    />
                  }
                  styles={
                    "py-2 sm:px-4 px-1 sm:!text-F16 !normal-case !text-F14 whitespace-nowrap flex  flex-row-reverse gap-2 "
                  }
                  type="secondary_transparent"
                />
         
                </div>
                <div
                  className="bg-borderColor lg:block hidden  h-10"
                  style={{ width: "1.5px" }}
                ></div>
                {typeSelect === "Projects" ? (
                     <div className="">
                     <ButtonComponent
                       onClick={handler2}
                       title={
                         selectedValueCondition === ""
                           ? GetAppText("category")
                           : selectedValueCondition
                       }
                       svg={
                         <SvgComponent
                           name={"ChevronDown"}
                           className={`w-2.5 h-2`}
                           stroke={"#1B1C1E"}
                           fill={"none"}
                         />
                       }
                       styles={"py-2 sm:px-4 px-1 sm:!text-F16 !text-F14 !normal-case whitespace-nowrap   "}
                       type="secondary_transparent"
                     />
                     <Dropdown
                       // category={true}
                       newCat={true}
                       mainStyle={"!h-56 sm:!w-44 !w-36 sm:-ml-0 -ml-10"}
                       handleSelect={handleSelectCondition}
                       isOpen={isDropdownOpen2}
                      //  options={info?.categories}
                      options={[
                            {id: 0,
            
              image: "",
              is_active: 1,
              slug: "all-1",
              title: "All",
              title_ar: "الكل"
          },
                        ...(info?.categories || []) // Ensure existing categories are preserved
                      ]}
                       selectedValues={selectedValues}
                       setIsDropdownOpen={setIsDropdownOpen2}
                     />
                   </div>
                ) : (
                  <div className="">
                    <ButtonComponent
                      onClick={handler2}
                      // title={
                      //   selectedGovernet === ""
                      //     ? GetAppText("subCategory")
                      //     : `${
                      //         language == "_ar"
                      //           ? selectedGovernet?.name_ar
                      //           : selectedGovernet?.name
                      //       }`
                      // }
                      title={
                        !selectedGovernet
                          ? GetAppText("Governate") // Show "Area" when deselected
                          : language == "_ar"
                          ? selectedGovernet?.name_ar
                          : selectedGovernet?.name
                      }
                      svg={
                        <SvgComponent
                          name={"ChevronDown"}
                          className={`w-2.5 h-2`}
                          stroke={"#1B1C1E"}
                          fill={"none"}
                        />
                      }
                      styles={"py-2 px-4 whitespace-nowrap   sm:!text-F16 !text-F14 !normal-case "}
                      type="secondary_transparent"
                    />
                    <Dropdown
                      governet={true}
                      // category={true}
                      mainStyle={"!h-56 sm:!w-48 !w-44"}
                      handleSelect={handleSelectGovernet}
                      isOpen={isDropdownOpen2}
                      options={[     {id: 0,
            
                        image: "",
                        is_active: 1,
                        slug: "all-1",
                        name: "All",
                        name_ar: "الكل"
                    },
                    ...(info?.governorates || [])]}
                 
                      selectedValues={selectedGovernet}
                      setIsDropdownOpen={setIsDropdownOpen2}
                    />
                  </div>
                )}
                {isButton && (
                  <ButtonComponent
                    onClickSvg={() => {
                      setIsButton(false);
                      setSelectedValueCondition("");
                      setCategoryParams("");
                    }}
                    title={selectedValueCondition}
                    svg={
                      <SvgComponent
                        name={"Cross"}
                        className={`w-2 h-2`}
                        stroke={"white"}
                        fill={"none"}
                      />
                    }
                    styles={"py-2 px-4 whitespace-nowrap   sm:!text-F16 !text-F14 !normal-case"}
                    type="primary"
                  />
                )}
                {typeSelect === "Projects" ? (
                <div>
                <ButtonComponent
                  onClick={handler}
                  title={
                    selectedValue === ""
                      ? GetAppText("subCategory")
                      : selectedValue
                  }
                  svg={
                    <SvgComponent
                      name={"ChevronDown"}
                      className={`w-2.5 h-2`}
                      stroke={"#1B1C1E"}
                      fill={"none"}
                    />
                  }
                  styles={"py-2 sm:px-4 px-1 sm:!text-F16 !text-F14 !normal-case whitespace-nowrap    "}
                  type="secondary_transparent"
                />
                <Dropdown
                  // sort={true}
                  category={true}
                  mainStyle={"!h-56 sm:!w-44 !w-40"}
                  handleSelect={handleSelectStatus}
                  isOpen={isDropdownOpen}
                  options={info?.tags}
                  selectedValues={selectedStatusValues}
                  setIsDropdownOpen={setIsDropdownOpen}
                />
              </div>
                ) : (
                  selectedGovernet && selectedGovernet?.id!==0 ?
                  <div className="">
                    <ButtonComponent
                      onClick={handler}
                      title={
                    
                           GetAppText("area")
                         
                      }
                      svg={
                        <SvgComponent
                          name={"ChevronDown"}
                          className={`w-2.5 h-2`}
                          stroke={"#1B1C1E"}
                          fill={"none"}
                        />
                      }
                      styles={"py-2 px-4 whitespace-nowrap  sm:!text-F16 !text-F14 !normal-case "}
                      type="secondary_transparent"
                    />
                    <Dropdown
                      area={true}
                      
                      mainStyle={"!h-56 sm:!w-48 !w-44"}
                      handleSelect={handleSelectArea}
                      isOpen={isDropdownOpen}
                      options={areas}
                      selectedValues={selectedAreaValues}
                      setIsDropdownOpen={setIsDropdownOpen}
                    />
                  </div>:""
                )}
                
        
              </div>
            </div>
            <div className={`sm:hidden flex w-full ${language==="_ar"?"":""}  gap-3 `}>
            <ButtonComponent
                  onClick={() => handleSelectType("Projects")}
                  title={GetAppText("projects")}
                  svg={
                    <SvgComponent
                      name={
                        typeSelect === "Projects"
                          ? "CheckboxFill"
                          : "CircleCheckboxUnfill"
                      }
                      className={`w-6 h-6`}
                      fill={"none"}
                    />
                  }
                  styles={
                    "py-2 sm:px-4 px-1 sm:!text-F16 !normal-case !text-F14 whitespace-nowrap flex  flex-row-reverse gap-2 "
                  }
                  type="secondary_transparent"
                />
                <ButtonComponent
                  onClick={() => handleSelectType("Mosques")}
                  title={GetAppText("masjid")}
                  svg={
                    <SvgComponent
                      name={
                        typeSelect === "Mosques"
                          ? "CheckboxFill"
                          : "CircleCheckboxUnfill"
                      }
                      className={`w-6 h-6`}
                      fill={"none"}
                    />
                  }
                  styles={
                    "py-2 sm:px-4 px-1 sm:!text-F16 !normal-case !text-F14 whitespace-nowrap flex  flex-row-reverse gap-2 "
                  }
                  type="secondary_transparent"
                />
       
                </div>
          </div>
          <SeparatorComponent className={"!my-8"} />
          <div
            className={`flex flex-wrap md:justify-normal   justify-center md:gap-6 gap-4 ${
              language === "_ar"
                ? "flex-row-reverse space-x-reverse"
                : "flex-row"
            } w-full`}
          >
            {loading ? (
              <div className="flex h-[440px] w-full justify-center items-center">
                <Loader />
              </div>
            ) : data?.length > 0 ? (
              data.map((item, index) => (
                <DonationCard
                  onImageClick={() => {
                    ImageClick(item);
                  }}
                  onCartClick={() => {
                    handleCartDetails(item);
                  }}
                  cardLoader={loadingStates[item.id]}
                  onClick={() => {
                    handleProjectClick(item);
                  }}
                  key={index}
                  donateType={typeSelect}
                  data={item}
                />
              ))
            ) : (
              !loading && (
                <div className="flex w-full h-[350px] justify-center text-F20 font-semibold items-center">
                  {GetAppText("no_data")}
                </div>
              )
            )}
          </div>
          <div className="sm:flex hidden justify-center w-full pt-4">
            <Pagination
              currentPage={currentPage}
              lastPage={lastPage}
              onPageChange={handlePageChange}
            />
          </div>
         {showPage!==lastPage && <div className="sm:hidden flex justify-center font-medium text-F18 w-full  pt-2">
          <ButtonComponent disabled={showLoading || showPage===lastPage} loading={showLoading} onClick={()=>handleShowMore()} styles={"!w-full !py-2.5  !border-2 !text-lightTextColor !border-borderColor"}  title={GetAppText("show_more")} type="transparent"/>
         
          </div>}
        </div>
      </div>
      {isAddModal && (
        <DonationModal
          isAddModal={isAddModal}
          details={details}
          setIsAddModal={setIsAddModal}
        />
      )}
    </>
  );
};

export default DonationProjects;
