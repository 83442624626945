import React from "react";
import { convertToNumbersInArabic, GetAppText } from "../../../../utils";
import { useSelector, useDispatch } from "react-redux";
import SvgComponent from "../../../components/SvgComponent";
import { StyleClass } from "../../../../utils";
import { switchTabHandler } from "../../../../store/slices/sideBarSlice";
import { modalHandler } from "../../../../store/slices/modalSlice";
export const Sidebar = ({data}) => {
  const selectedOption = useSelector((state) => state.selectedOption.value);
  const language = useSelector((state) => state.language.value);
  const dispatch = useDispatch();

  const textAlignStyle = language === "_ar" ? "right" : "left";


  
  const handleSelectOption = (option) => {
    dispatch(switchTabHandler(option.id));
  };

  const Logout=()=>{
    dispatch(modalHandler("LogoutModal"));
  }
  const accountData = [
    {
      id: 1,
      name: GetAppText("personal_information"),
      component: "",
      icon: "User",
    },
    {
      id: 2,
      name: GetAppText("account_settings"),
      component: "",
      icon: "Settings",
    },
    {
      id: 3,
      name: GetAppText("donation_record"),
      component: "",
      icon: "Clock",
    },
  ];
  const Endowments_data = [
    {
      id: 4,
      name: GetAppText("about_sunni_endowments"),
      component: "",
      icon: "InfoCircle",
    },
    {
      id: 5,
      name: GetAppText("terms_use_conditions"),
      component: "",
      icon: "Document",
    },
    {
      id: 6,
      name: GetAppText("privacy_policy"),
      component: "",
      icon: "Terms",
    },
  ];
  const help_support = [
    {
      id: 7,
      name: GetAppText("frequently_asked_questions"),
      component: "",
      icon: "Question",
    },
    {
      id: 8,
      name: GetAppText("contact_us"),
      component: "",
      icon: "Phone",
      sub_heading: GetAppText("contact_desc"),
    },
  ];
  const getFirstLetter = (str) => {
    return str ? str.charAt(0).toUpperCase() : '';
  };
  return (
    <div className="w-full h-fit rounded-xl bg-white">
      <div className="w-full h-fit bg-custom-gradient-background rounded-tr-xl rounded-tl-xl py-6">
        <div className="space-y-4 items-center justify-center flex flex-col">
          <div className="w-12 h-12 rounded-full bg-secondary flex items-center justify-center">
            <span className="font-bold text-F26 text-white">{getFirstLetter(data?.name)}</span>
          </div>
          <h1 className="text-white text-F24 font-bold">
            {/* {GetAppText("test_user_name")} */}
            {data?.name}
          </h1>
          {/* <span className="text-white text-F16">{data?.country?.phone_code} {data?.phone_number}</span>
           */}
     <div className={`${language==="_ar"?"flex-row-reverse space-x-reverse":""} flex space-x-2 text-white text-F16`}>
                            <div> {language==="_en"&& data?.country?.phone_code} </div> <div>  {language==="_ar"? convertToNumbersInArabic(data?.phone_number):data?.phone_number}</div> <div> {language==="_ar"&& convertToNumbersInArabic(data?.country?.phone_code)}</div>

                             </div>
        </div>
      </div>
      <div className="py-4 md:px-6 px-3">
        <div className="space-y-8">
          <div className="space-y-4">
            <span
              className="block text-F14 text-secondaryTextColor"
              style={{
                textAlign: textAlignStyle,
              }}
            >
              {GetAppText("my_account")}
            </span>
            <div className="space-y-3">
              {accountData.map((item, index) => (
                <div
                  className={`p-3 rounded-md ${
                    selectedOption === item.id
                      ? language === "_ar"
                        ? "border-l-[6px] border-secondary"
                        : "border-r-[6px] border-secondary"
                      : ""
                  }`}
                  style={{
                    backgroundColor:
                      selectedOption === item.id
                        ? "rgba(206, 136, 56, 0.05)"
                        : "#F6F6F6",
                    cursor:
                      selectedOption === item.id ? "not-allowed" : "pointer",
                  }}
                  key={index}
                  onClick={() => handleSelectOption(item)}
                >
                  <div
                    className={`flex justify-between items-center ${StyleClass()}`}
                  >
                    <div
                      className={`flex items-center space-x-3  ${StyleClass()}`}
                    >
                      <SvgComponent
                        name={item.icon}
                        className={"w-4 h-4"}
                        stroke={
                          selectedOption === item.id ? "#E49E4E" : "#727272"
                        }
                        fill={"none"}
                      />
                      <span
                        className={` md:text-F14 text-F12 ${language === "_ar" ? "text-right" : "text-left"} font-semibold ${
                          selectedOption === item.id
                            ? "text-secondary"
                            : "text-secondaryTextColor"
                        }`}
                      >
                        {item.name}
                      </span>
                    </div>
                    <div>
                      {selectedOption === item.id ? null : (
                        <SvgComponent
                          name={
                            language === "_ar" ? "ChevronLeft" : "ChevronRight"
                          }
                          className={"w-5 h-5"}
                          stroke={
                            selectedOption === item.id ? "#E49E4E" : "#727272"
                          }
                          fill={"none"}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="space-y-4">
            <span
              className="block text-F14 text-secondaryTextColor"
              style={{
                textAlign: textAlignStyle,
              }}
            >
              {GetAppText("sunni_endowments")}
            </span>
            <div className="space-y-3">
              {Endowments_data.map((item, index) => (
                <div
                  className={`md:p-3 py-3 p-2 rounded-md ${
                    selectedOption === item.id
                      ? language === "_ar"
                        ? "border-l-[6px] border-secondary"
                        : "border-r-[6px] border-secondary"
                      : ""
                  }`}
                  key={index}
                  onClick={() => handleSelectOption(item)}
                  style={{
                    backgroundColor:
                      selectedOption === item.id
                        ? "rgba(206, 136, 56, 0.05)"
                        : "#F6F6F6",
                    cursor:
                      selectedOption === item.id ? "not-allowed" : "pointer",
                  }}
                >
                  <div
                    className={`flex justify-between items-center ${StyleClass()}`}
                  >
                    <div
                      className={`flex items-center space-x-3  ${StyleClass()}`}
                    >
                      <SvgComponent
                        name={item.icon}
                        className={"w-4 h-4"}
                        stroke={
                          selectedOption === item.id ? "#E49E4E" : "#727272"
                        }
                        fill={"none"}
                      />
                      <span
                        className={` md:text-F14 text-F12 font-semibold ${
                          selectedOption === item.id
                            ? "text-secondary"
                            : "text-secondaryTextColor"
                        } ${language === "_ar" ? "text-right" : "text-left"}`}
                      >
                        {item.name}
                      </span>
                    </div>
                    <div>
                      {selectedOption === item.id ? null : (
                        <SvgComponent
                          name={
                            language === "_ar" ? "ChevronLeft" : "ChevronRight"
                          }
                          className={"w-5 h-5"}
                          stroke={
                            selectedOption === item.id ? "#E49E4E" : "#727272"
                          }
                          fill={"none"}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="space-y-4">
            <span
              className="block text-F14 text-secondaryTextColor"
              style={{
                textAlign: textAlignStyle,
              }}
            >
              {GetAppText("help_support")}
            </span>
            <div className="space-y-3">
              {help_support.map((item, index) => (
                <div
                  className={`p-3 rounded-md ${
                    selectedOption === item.id
                      ? language === "_ar"
                        ? "border-l-[6px] border-secondary"
                        : "border-r-[6px] border-secondary"
                      : ""
                  }`}
                  key={index}
                  onClick={() => handleSelectOption(item)}
                  style={{
                    backgroundColor:
                      selectedOption === item.id
                        ? "rgba(206, 136, 56, 0.05)"
                        : "#F6F6F6",
                    cursor:
                      selectedOption === item.id ? "not-allowed" : "pointer",
                  }}
                >
                  <div
                    className={`flex justify-between items-center ${StyleClass()}`}
                  >
                    <div
                      className={`flex items-center space-x-3  ${StyleClass()}`}
                    >
                      <SvgComponent
                        name={item.icon}
                        className={"w-4 h-4"}
                        stroke={
                          selectedOption === item.id ? "#E49E4E" : "#727272"
                        }
                        fill={"none"}
                      />
                      <span
                        className={` md:text-F14 text-F12 font-semibold ${
                          selectedOption === item.id
                            ? "text-secondary"
                            : "text-secondaryTextColor"
                        } ${language === "_ar" ? "text-right" : "text-left"}`}
                      >
                        {item.name}
                      </span>
                    </div>
                    <div>
                      {selectedOption === item.id ? null : (
                        <SvgComponent
                          name={
                            language === "_ar" ? "ChevronLeft" : "ChevronRight"
                          }
                          className={"w-5 h-5"}
                          stroke={
                            selectedOption === item.id ? "#E49E4E" : "#727272"
                          }
                          fill={"none"}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div onClick={Logout} className="p-3 bg-bodyColor rounded-md cursor-pointer">
            <div className={`flex items-center space-x-3 ${StyleClass()}`}>
              <SvgComponent
                name={"Logout"}
                className={"w-4 h-4"}
                stroke={"#E94747"}
                fill={"none"}
              />
              <span
                className={`text-errorColor md:text-F14 text-F12 ${
                  language === "_ar" ? "text-right" : "text-left"
                } font-semibold `}
              >
                {GetAppText("logout")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
