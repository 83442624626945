import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation,Autoplay,Scrollbar  } from "swiper/modules";
import SvgComponent from "../../../components/SvgComponent";
import { GetAppText, StyleClass } from "../../../../utils";
import { ButtonComponent } from "../../../components/ButtonComponent";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import {ReactComponent as Texture} from "../../../../assets/svgs/texture.svg"
import {ReactComponent as Texture2} from "../../../../assets/svgs/texture2.svg"
import { Loader } from "../../../components/Loader";
import { Image_URL } from "../../../../utils/BaseUrl";

const CustomSwiper = ({data,loading}) => {
  const swiperRef = useRef();
  const language = useSelector((state) => state.language.value);
  const navigate = useNavigate();
const handle_learnmore=()=>{
  navigate(`/about-us`);
}
const handle_donate=(val)=>{
  
  if (val?.item_type=== "App\\Models\\Project") {
    navigate(`/donation-project/${val?.item?.slug}`);
  } else {
    navigate(`/donation-mosque/${val?.item?.slug}`);
  }
}

const [isMdScreen, setIsMdScreen] = useState(window.innerWidth >= 768);

useEffect(() => {
  const handleResize = () => {
    setIsMdScreen(window.innerWidth >= 768);
  };
  
  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
}, []);


const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const handleSlideChange = () => {
    if (swiperRef.current) {
      setIsBeginning(swiperRef.current.isBeginning);
      setIsEnd(swiperRef.current.isEnd);
    }
  };

  return (
    <div className="w-full md:h-[650px] h-[740px]">
      <div className="h-full w-full">
     { loading ?
         <div className="flex h-[600px] w-full justify-center items-center">
         <Loader/>
         </div>
         :
         data && data?.length>0? 
         <Swiper
          // spaceBetween={30}
          slidesPerView={1}
          loop={true}
          allowTouchMove={false}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          modules={[Navigation, Autoplay]} // Include Autoplay
          // autoplay={{
          //   delay: 3000, // Set the delay in milliseconds (3 seconds in this case)
          //   disableOnInteraction: false, // Allows autoplay to continue after interaction
          // }}
         
          className="w-full h-full relative"
        >
          {/* <div>
          {
            swiperImages?.map((item)=>(
              <SwiperSlide>
                <div className={`flex h-full ${language === "_ar" ? "" : "flex-row-reverse space-x-reverse"} bg-custom-gradient-background `}>
              <img
              key={item?.id}
                src={item?.svg}
                alt="Slides"
                className="w-6/12 h-full object-cover "
                style={{clipPath: `${language === "_ar" ? "polygon(0 0, 86% 0, 100% 100%, 0% 100%)" : "polygon(0 0, 100% 0, 100% 100%, 16% 100%)"}
                  `}}
              />
              

              <div className={`w-6/12 h-full  relative ${language === "_ar" ? "sm:pr-20 pr-3" : "sm:pl-20 pl-3"}   py-6 flex  items-center`}>
      <div className="absolute bottom-0 left-[-90px]">
    
        <Texture className="object-cover"/>
      </div>
      <div className="absolute top-[-20px] right-0 ">

            <Texture2/>
      </div>
      <div className="h-full  flex flex-col  md:pt-44 pt-32 space-y-4">
        <div className={`flex ${language === "_ar" ? "flex-row-reverse" : ""}  text-F20 text-white`}>
        [الحج: 77]
        </div>
        <div className={`md:text-F39  ${language === "_ar" ? "text-right" : "text-left"} text-F30  font-extrabold text-white`}>
        <div className="">
        {ayat1}
      </div>
      <div className="text-secondary"> {ayat2}</div>

      </div>
      </div>
      </div>
      </div>
            </SwiperSlide>
            ))
          }
       
 

</div> */}
{  data?.length>0? 
         data?.map((item,index)=>(
          <SwiperSlide key={index}>
            <div   className={`flex md:flex-row flex-col-reverse relative  h-full   bg-custom-gradient-background `}>
          <img
     
            src={`${Image_URL}${item?.image}`}
            alt="Slides"
            className="md:w-6/12 h-full  w-full object-cover "
            style={{
              clipPath:isMdScreen?"polygon(0 0, 90% 0, 100% 100%, 0% 100%)":"none"
              // clipPath: isMdScreen
              //   ? `${language === "_ar" ? "polygon(0 0, 86% 0, 100% 100%, 0% 100%)" : "polygon(0 0, 100% 0, 100% 100%, 16% 100%)"}`
              //   : "none", // No clipPath on smaller screens
            }}
          />
           <div
    className="absolute md:hidden inset-0"
    style={{
      background: "linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))",
    }}
  ></div>
          <div className="md:hidden block absolute z-30  top-[295px] px-5">
              <div className={`  ${language === "_ar" ? "text-right  text-F36" : "text-left md:text-F34 text-F26"}   font-extrabold text-white`}>
    <div className="">
    {language === "_ar" ? item?.title_ar:item?.title}
  </div>
  <div className="text-secondary">  {language === "_ar" ? item?.subtitle_ar:item?.subtitle}</div>

  </div>
  </div>
          <div className={`md:w-6/12 md:block hidden  w-full md:h-full h-3/6   relative ${language === "_ar" ? "lg:pr-20 md:pr-8 pr-3" : "  pl-0"}   md:py-6 flex  items-center`}>
  <div className="absolute bottom-0 left-[-90px]">

    <Texture className="object-cover"/>
  </div>
  <div className="absolute top-[-20px] right-0 ">

        <Texture2/>
  </div>
  <div className="h-full  flex flex-col w-full xl:pt-40 lg:pt-36 md:pt-32 pt-6 md:space-y-4  space-y-2">
    <div className={`flex ${language === "_ar" ? "flex-row-reverse" : ""}  text-F20 text-white`}>
    {language === "_ar" ? item?.other_text_ar:item?.other_text}
    </div>
    <div className={`  ${language === "_ar" ? "text-right lg:leading-20 xl:text-F48 lg:text-F40 md:text-F40  text-F27" : "text-left lg:text-F40 md:text-F36 text-F25"} 
     font-extrabold text-white`}>
    <div className="">
    {language === "_ar" ? item?.title_ar:item?.title}
  </div>
  <div className={`text-secondary ${language === "_ar" ? "pt-2":""}`}>  {language === "_ar" ? item?.subtitle_ar:item?.subtitle}</div>
  <div
                className={`w-full space-y-3 md:hidden block py-10 ${StyleClass()}`}
              >
                <ButtonComponent
                onClick={()=>{handle_donate(data[swiperRef.current?.realIndex])}}
                  title={GetAppText("donate")}
                  styles={"!px-6 w-full md:!text-F20 text-F18"}
                  type="secondary"
                />
                <ButtonComponent
                onClick={handle_learnmore}
                  title={GetAppText("learn_more")}
                  type="transparent"
                  styles={"!px-6 w-full md:!text-F20 text-F18"}
                />
              </div>
  </div>
  </div>
  </div>
  </div>
        </SwiperSlide>
        ))
:
(!loading && <div className="flex w-full justify-center text-F20 font-semibold items-center">
           {GetAppText("no_data")}
              </div>)}
 

          {/* Custom Navigation Buttons */}
          <div className="relativeflex flex-row-reverse">
            <div
              className={`absolute z-50  md:bottom-10 bottom-2   
                 ${
                language === "_ar" ? " lg:right-20 md:right-8 right-4" : "xl:right-20 lg:right-16 md:right-12 right-4  sm:w-[44%]"
              }
                
                
               `}
            >
              <div
                className={`md:flex items-center space-x-2.5 xl:mb-16 md:mb-12  hidden mb-12 ${StyleClass()}`}
              >
                <ButtonComponent
                onClick={()=>{handle_donate(data[swiperRef.current?.realIndex])}}
                  title={GetAppText("donate")}
                  styles={"!px-6  md:!text-F20 text-F18"}
                  type="secondary"
                />
                <ButtonComponent
                onClick={handle_learnmore}
                  title={GetAppText("learn_more")}
                  type="transparent"
                  styles={"!px-6  md:!text-F20 text-F18"}
                />
              </div>
            </div>
            <div
              className={`absolute md:block hidden  z-50 xl:bottom-10 lg:bottom-8 md:bottom-8  bottom-40 
                 ${
                language === "_ar" ? " lg:right-20 md:right-8 right-4" : "xl:right-20 lg:right-16 md:right-10 right-4   sm:w-[44%]"
              }
                `}
            >
              <div className="flex  items-center space-x-2.5 w-full md:right-0">
                <div
                  className={`border-2 border-white p-2 rounded-full cursor-pointer`}
                  onClick={() => {
                    console.log("wiperRef.current?.slidePrev()",swiperRef.current?.slidePrev())
                    swiperRef.current?.slidePrev()}}
                >
                  <SvgComponent
                    name="ArrowLeft"
                    stroke={"white"}
                    fill={"none"}
                    className="md:w-6 md:h-6 h-4 w-4"
                  />
                </div>
                <div
                  className="border-2 border-white p-2 rounded-full cursor-pointer"
                  onClick={() => swiperRef.current?.slideNext()}
                >
                  <SvgComponent
                    name="ArrowRight"
                    stroke={"white"}
                    fill={"none"}
                    className="md:w-6 md:h-6 h-4 w-4"
                  />
                </div>
              </div>
           
            </div>
            <div  className={`md:hidden block  
               `}>
              <div className="flex items-center left-6 space-x-2.5 absolute  top-60 z-50  ">
                <div
                  className="border-2 border-white p-2 rounded-full cursor-pointer"
                  onClick={() => swiperRef.current?.slidePrev()}
                >
                  <SvgComponent
                    name="ArrowLeft"
                    stroke={"white"}
                    fill={"none"}
                    className="md:w-6 md:h-6 h-5 w-5"
                  />
                </div>
               
              </div>
              <div className="flex items-center right-6 space-x-2.5 absolute  top-60 z-50  ">
              <div
                  className="border-2 border-white p-2 rounded-full cursor-pointer"
                  onClick={() => swiperRef.current?.slideNext()}
                >
                  <SvgComponent
                    name="ArrowRight"
                    stroke={"white"}
                    fill={"none"}
                    className="md:w-6 md:h-6 h-5 w-5"
                  />
                </div>
                </div>
                <div className="absolute z-50 bottom-4 w-full">
                <div
                className={`w-full space-y-3  md:hidden block px-5 ${StyleClass()}`}
              >
          
                <ButtonComponent
                onClick={handle_learnmore}
                  title={GetAppText("learn_more")}
                  type="transparent"
                  styles={"!px-6 w-full md:!text-F20 text-F18"}
                />
                      <ButtonComponent
                onClick={()=>{handle_donate(data[swiperRef.current?.realIndex])}}
                  title={GetAppText("donate")}
                  styles={"!px-6 w-full md:!text-F20 text-F18"}
                  type="secondary"
                />
              </div>
                </div>
                </div>
          </div>
        </Swiper>:
      (!loading && <div className="flex w-full justify-center h-full bg-custom-gradient-background text-white pt-10 text-F24 font-semibold items-center">
        {GetAppText("no_data")}
           </div>)}
      </div>
    </div>
  );
};

export default CustomSwiper;
