import React, { useEffect, useRef, useState } from "react";
import { convertToArabicNumbers, convertToNumbersInArabic, GetAppText, secondaryColor, StyleClass, ValidationErrors } from "../../../../utils";
import { useSelector,useDispatch } from "react-redux";
import SvgComponent from "../../../components/SvgComponent";
import { ButtonComponent } from "../../../components/ButtonComponent";
import { ProgressBar } from "../../../components/ProgressBar";
import { InputComponent } from "../../../components/InputComponent";

import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation,Autoplay  } from "swiper/modules";
import { modalHandler } from "../../../../store/slices/modalSlice";
import "swiper/css";
import "swiper/css/navigation";
import { Loader } from "../../../components/Loader";
import { apiRoutes } from "../../../../apis/routes";
import { postRequest } from "../../../../apis/methods";
import { Image_URL } from "../../../../utils/BaseUrl";

const UrgentProjects = ({data,loading}) => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.value);
  const [addloading,setAddLoading]=useState(false)
  const [donateloading,setDonateLoading]=useState(false)

  
  const [price, setPrice] = useState(
    data?.reduce((acc, project) => {
      // Check if price exists and set it, otherwise default to an empty string
      acc[project.id] = project.cart_item?.price || '';
      return acc;
    }, {})
  );

// const [prices, setPrices] = useState(data?.map(() => 0)); // Initialize prices state



// Update the price for a specific card
// const handlePriceChange = (e, itemId) => {
//   const value = e.target.value;
//   setPrice(prevPrices => ({
//     ...prevPrices,
//     [itemId]: value === "" ? "" : parseFloat(value) // Ensure value is either a number or empty
//   }));
// };

  const swiperRef = useRef();
const navigate = useNavigate();
const handleClick=(item)=>{
  navigate(`/donation-project/${item?.slug}`);
    // navigate(`/donars-bag`);
 
}
const handleProjectClick = (id) => {

  navigate(`/donation-project/${id}`);
};
// const data=[
//   {id:1,name:"Title 1",subName:""},
//   {id:2,name:"Title 2",subName:""},
//   {id:3,name:"Title 3",subName:""},
// ]

const handleSubmit = async (item) => {
  // Prevent multiple submissions
if (addloading) return; 
// Prevent multiple submissions

const val = price[item.id]; // Get the price for this specific item
  // const data = {
  //   itemable_type: "project",
  //   itemable_id: item?.id,
  //   price: parseFloat(val)  
  // };
  const data = {
    cart_type:"CART",
    is_edit:item?.cart_item?true:false,
    items: [
        {
            id:item?.id,
            type:"PROJECT",
            price: parseFloat(val)
        }
    ]
  }

  setAddLoading(true)
if(val){
  try {
    const response = await postRequest(apiRoutes.add_cart, data); // Replace with your endpoint
    
 
    if (response.status === "success") { 
      setAddLoading(false)
      let error = '';
  if (
    response?.message?.includes(ValidationErrors.addCartItem.added)
  ) {
    error = GetAppText('added_cart');
  
  } 
  else if (
    response?.message?.includes(ValidationErrors.addCartItem.updated)
  ) {
    error = GetAppText('updated_cart');
    
  } 


      dispatch(modalHandler({ type: "toast", msg: error}));
      setTimeout(()=>{
          dispatch(modalHandler(""));
        },[500])

        // setPrice(prevPrices => ({
        //   ...prevPrices,
        //   [item.id]: "" // Clear the price for this specific item
        // }));
 
    } else {
      setAddLoading(false)
      let error=""
      if (
        response?.data?.message?.includes(ValidationErrors.addCartItem.limit_error)
      ) {
        error = `${GetAppText('not_exceed_amount')}`;
   
      } 
     else if (
        response?.data?.message?.includes(ValidationErrors.addCartItem.exceedGoalAmount)
      ) {
        error = `${GetAppText('limit_price_cart')} ${item?.remaining_goal_amount?.toLocaleString()}.`;
   
      } 
      else if (
        response?.data?.message?.includes(ValidationErrors.addCartItem.lessAmount)
      ) {
        error = `${GetAppText('amount_less')}`;
   
      } 


      else {
        // error = GetAppText('something_went_wrong');
        error= response?.data?.message?.replace(/-/g, " ");
      }
      dispatch(modalHandler({ type: "toastError", msg:error}));
      setTimeout(()=>{
          dispatch(modalHandler(""));
        },[500])
        // setPrice(prevPrices => ({
        //   ...prevPrices,
        //   [item.id]: "" // Clear the price for this specific item
        // }));
     
    }
  } 
  catch (error) {
    console.error('Error fetching data:', error);
  } }
  else{
      setAddLoading(false)
    
      dispatch(modalHandler("priceError"));
      setTimeout(()=>{
          dispatch(modalHandler(""));
        },[500])

  }

};

const handle_share=(item)=>{
  const currentURL = window.location.href;
  // navigate(`/donation-project/${val?.slug}`)
const fullLink=`${currentURL}donation-project/${item?.slug}`
    // Copy the link to the clipboard
    navigator.clipboard.writeText(fullLink).then(() => {
      dispatch(modalHandler({type:"share",msg:GetAppText("link")}));
      setTimeout(()=>{
        dispatch(modalHandler(""));
      },100)
    });

}

const handleDonate = async (item) => {
  // Prevent multiple submissions
if (donateloading) return; 
// Prevent multiple submissions
   const englishPhoneNumber = convertToArabicNumbers(price[item.id]);
const val = englishPhoneNumber;
const data = {
  cart_type:"DONATION",
  is_edit:item?.cart_item?true:false,
  items: [
      {
          id:item?.id,
          type:"PROJECT",
          price: parseFloat(val)
      }
  ]
}
  // const data = {
  //   itemable_type: "project",
  // itemable_id: item?.id,
  //   price: parseFloat(val)  
  // };
  setDonateLoading(true)
if(price){
  try {
    const response = await postRequest(apiRoutes.add_cart, data); // Replace with your endpoint
   
 
    if (response.status === "success") { 
      setDonateLoading(false)
 
      // dispatch(modalHandler({ type: "detailtoast", msg: response?.message}));
      setTimeout(()=>{
          dispatch(modalHandler(""));
        },[500])

        setPrice("");
        navigate(`/checkout/donation`);
    }
    else {
      setDonateLoading(false)
      let error=""
      if (
        response?.data?.message?.includes(ValidationErrors.addCartItem.limit_error)
      ) {
        error = `${GetAppText('not_exceed_amount')}`;
   
      } 
     else if (
        response?.data?.message?.includes(ValidationErrors.addCartItem.exceedGoalAmount)
      ) {
        error = `${GetAppText('limit_price_cart')} ${item?.remaining_goal_amount?.toLocaleString()}.`;
   
      } 
      else if (
        response?.data?.message?.includes(ValidationErrors.addCartItem.lessAmount)
      ) {
        error = `${GetAppText('amount_less')}`;
   
      } 
      else {
        // error = GetAppText('something_went_wrong');
        error= response?.data?.message?.replace(/-/g, " ");
      }
      dispatch(modalHandler({ type: "toastError", msg:error}));
      setTimeout(()=>{
          dispatch(modalHandler(""));
        },[500])
        // setPrice(prevPrices => ({
        //   ...prevPrices,
        //   [item.id]: "" // Clear the price for this specific item
        // }));
     
    }
  } 
  catch (error) {
    console.error('Error fetching data:', error);
  } }
  else{
    setDonateLoading(false)
   
    dispatch(modalHandler("priceError"));
      setTimeout(()=>{
          dispatch(modalHandler(""));
        },[500])

  }

};

const arabicDigits = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
const englishDigits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
const handlePriceChange = (e, itemId) => {
  let value = e.target.value;

  // If language is Arabic, convert Arabic digits back to English for processing
  if (language === "_ar") {
    value = value
      .split("")
      .map((char) => {
        const index = arabicDigits.indexOf(char);
        return index > -1 ? englishDigits[index] : char;
      })
      .join("");
  }

  // Remove non-numeric characters
  const numericValue = value.replace(/[^0-9]/g, "");

  if (numericValue === "") {
    // Allow empty value for clearing input
    setPrice((prevPrices) => ({
      ...prevPrices,
      [itemId]: "",
    }));
    return;
  }

  if (language === "_ar") {
    // Convert English digits to Arabic digits for display
    const arabicValue = numericValue
      .split("")
      .map((digit) => arabicDigits[Number(digit)])
      .join("");

    setPrice((prevPrices) => ({
      ...prevPrices,
      [itemId]: arabicValue, // Store the Arabic value for display
    }));
  } else {
    setPrice((prevPrices) => ({
      ...prevPrices,
      [itemId]: parseFloat(numericValue), // Store the numeric value
    }));
  }
};




  return (
    <div className="w-full h-full md:space-y-8 space-y-0 md:py-0 py-10 md:bg-transparent bg-primary">
 
      <div
        className={`flex justify-between md:px-0 px-6 items-center ${
          language === "_ar" ? "flex-row-reverse" : ""
        }`}
      >
        <h1 className={`md:text-primary text-white md:text-F32 text-F26 font-semibold`}>
          {GetAppText("urgent_projects")}
        </h1>
        <div className="flex flex-row items-center space-x-2">
          <div
          onClick={() => swiperRef.current?.slidePrev()}
            className={`flex justify-center items-center md:p-2 p-1 cursor-pointer border-2 md:border-black border-white rounded-full`}
            // disabled={!canScrollLeft}
          >
            <SvgComponent
              name={"ArrowLeft"}
              className={"md:w-6 md:h-6 w-5 h-5 md:text-black text-white"}
              stroke={"currentColor"}
              fill={"none"}
            />
          </div>
          <div
           onClick={() => swiperRef.current?.slideNext()}
            className={`flex justify-center items-center md:p-2 p-1 cursor-pointer border-2 md:border-black border-white rounded-full`}
            // disabled={!canScrollRight}
          >
            <SvgComponent
              name={"ArrowRight"}
              className={"md:w-6 md:h-6 w-5 h-5 md:text-black text-white"}
              stroke={"currentColor"}
              fill={"none"}
            />
          </div>
        </div>
      </div>


 {     loading ?
             <div className="flex  w-full justify-center h-[300px] items-center">
             <Loader/>
             </div>:
      <Swiper
          spaceBetween={30}
          slidesPerView={1}
          loop={true}
          allowTouchMove={false}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          modules={[Navigation, Autoplay]} // Include Autoplay
          // autoplay={{
          //   delay: 4000, // Set the delay in milliseconds (3 seconds in this case)
          //   disableOnInteraction: false, // Allows autoplay to continue after interaction
          // }}
     
          className="w-full h-full  rounded-xl relative"
        >
          {
             loading ?
             <div className="flex  w-full justify-center h-[300px] items-center">
             <Loader/>
             </div>
             : data?.length>0?
            data?.map((item,index)=>(
              <SwiperSlide key={index} className=" rounded-xl">
              <div  className={`w-full md:flex md:flex-row flex rounded-xl flex-col ${
                language === "_ar" ? "md:flex-row-reverse" : ""
              }  md:h-[620px]  `}>

              {/* Content Container */}
              <div
                className={`md:w-7/12 bg-primary   relative md:py-16 py-5 px-8 lg:px-16 md:px-14 ${
                  language === "_ar"
                    ? "md:rounded-tr-xl  md:rounded-br-xl "
                    : " md:rounded-tl-xl  md:rounded-bl-xl "
                }`}
              >
                <div className="absolute  top-0 right-0">
                  <img
                    alt=""
                    src={require("../../../../assets/images/Vector3.png")}
                  />
                </div>
                <div className="flex flex-col justify-between">
                <div className="relative">
                {/* Rest of your content */}
                <div className="md:mb-14 mb-24">
                <div className={`flex  ${language === "_ar" ? "md:flex-row-reverse md:justify-between items-end" : "md:flex-row md:justify-between items-start "} flex-col-reverse md:items-center  absolute z-20  w-full ${StyleClass()}`}>
                  <h1 className={`text-white font-bold md:mt-0 mt-5 text-F20   line-clamp-1`}>
                    {/* {GetAppText("building_schools")} */}
                    {language === "_ar" ?item?.title_ar:item?.title}
                  </h1>
             
                    <ButtonComponent
                    tooltip={GetAppText("link_share")}
                    title={GetAppText("sharing")}
                    svg={
                      <SvgComponent
                        name={"ShareSvg"}
                        className={"w-4 h-4"}
                        stroke={"white"}
                        fill={"none"}
                      />
                    }
                    onClick={()=>{handle_share(item)}}
                    styles={"!py-2 !w-fit !px-3 !hover:!bg-opacity-50  !text-F16"}
                    type="transparent"
                  />
                </div>
                </div>
                <div className="py-5">
                  <div className={`text-white text-F16 ${language === "_ar" ? "text-right" : "text-left"} line-clamp-4`}>
                  {language === "_ar" ?item?.short_description_ar:item?.short_description}
                  </div>
                </div>
                <div className={`flex items-center pt-3 justify-between ${StyleClass()}`}>
                  { <div className={`flex items-center space-x-2 ${StyleClass()}`}>
               { item?.end_date &&  <>
                    <SvgComponent
                      name={"Clock"}
                      stroke={"white"}
                      fill={"none"}
                      className={"w-5 h-5"}
                    />
                    <span className="text-white md:text-F18 text-F16 uppercase flex flex-row-reverse">
                  {  language === "_ar" && GetAppText("remaining")}   {item?.days_left} {GetAppText("day")}  { language === "_en" &&GetAppText("remaining")}
                    </span>
                    </>}
                  </div>}
                  <div className={`flex items-center space-x-2 ${StyleClass()}`} onClick={()=>handleProjectClick(item?.slug)}>
                    <span className="text-secondary cursor-pointer hover:underline font-bold  uppercase md:text-F18 text-F16">
                      {GetAppText("details")}
                    </span>
                    <SvgComponent
                      name={language === "_ar" ? "ArrowLeft" : "ArrowRight"}
                      stroke={secondaryColor}
                      fill={"none"}
                      className={"w-5 h-5"}
                    />
                  </div>
                </div>
              <div className="md:pt-14 pt-14">
              {item?.goal_amount>0 &&       <ProgressBar progressStyle={"!bg-[#697B84]"} value={item?.goal_percentage} />
}</div>
                <div className="pt-6  h-full">
                {item?.goal_amount>0&&  <div
                    className={`flex items-center justify-between ${StyleClass()}`}
                  >
                    <div className="flex flex-col  space-y-2">
                      <span
                        className={`text-white md:text-F18 text-F16 ${
                          language === "_ar" ? "text-right" : ""
                        }`}
                      >
                        {GetAppText("donated")}
                      </span>
                      <span className="text-white  md:text-F18 text-F16">{item?.donated_amount.toLocaleString()} {language === "_ar" ? "د.ب":"BHD"}</span>
                    </div>
                    <div className="flex flex-col  space-y-2">
                      <span
                        className={`text-white  md:text-F18 text-F16 ${
                          language === "_ar" ? "text-right" : ""
                        }`}
                      >
                        {GetAppText("goal")} 
                      </span>
                      <span className="text-white  md:text-F18 text-F16">{item?.goal_amount?.toLocaleString()} {language === "_ar" ? "د.ب":"BHD"}</span>
                    </div>
                  </div>}
          
                </div>
                </div>
          {/* {item?.goal_percentage<100 &&   */}
          
          <div className={` ${item?.goal_amount || item?.end_date?"lg:pt-12 pt-8":"lg:pt-24 pt-14"} `}>
                    <div className={`flex lg:flex-row ${language=="_ar"?"lg:flex-row-reverse":""} w-full sm:justify-between  flex-col-reverse gap-3  lg:space-y-0   space-y-3 lg:items-center `}>
                      <div className="lg:w-8/12">
                        <InputComponent
                          title={GetAppText("donation_amount")}
                          isTranslate={false}
                          type="text"  input_style={'!py-3 !text-black'} 
                          // value={price}  
                          // onChange={(e) => setPrice(e.target.value)}
                          // value={price[item.id] || ""} 
                          // Bind to specific item's price

   value={language==="_ar"?convertToNumbersInArabic(price[item.id]):convertToArabicNumbers(price[item.id])}

                          onChange={(e) => handlePriceChange(e, item.id)} // Update only this card's price
                        />
                      </div>
                      <div className={`-lg:w-6/12  `}>
                        <div
                          className={`flex items-center lg:w-fit w-full space-x-2 ${StyleClass()} ${
                            language === "_ar"
                              ? "justify-self-end"
                              : "justify-self-end"
                          }`}
                        >
                          <div className="w-full lg:w-fit">
                            <ButtonComponent
                             loading={donateloading}
                             disabled={donateloading || addloading}
                              title={GetAppText("donate_now")}
                              type="secondary"
                              styles={`whitespace-nowrap !py-2 lg:!w-fit !w-full ${donateloading?"!px-3":"!px-9"} `}
                              onClick={()=>{handleDonate(item)}}
                            />
                          </div>
                          {/* <ButtonComponent
                          loading={addloading}
                          disabled={donateloading || addloading}
                  styles={` !px-4 !border-none ${addloading?"!py-2.5":" !py-3.5"} !rounded-[8px]  !bg-[#697B84]`}
                  onClick={()=>{handleSubmit(item)}}
                  svg={
                    <SvgComponent
                      name={"Bag"}
                      className={"w-[21px] h-[22px]"}
                      stroke={"white"}
                      fill={"none"}
                    />
                  }
                  title={""}
                  type="primary"
                /> */}
                      
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* } */}
                  </div>
              </div>
              {/* Image Container */}
              <div className={` md:w-6/12  md:h-full h-[240px]  md:px-0 px-6 gap-0 items-center   ${
                language === "_ar" ?  "rounded-xl "
                    : " md:rounded-tr-xl  md:rounded-br-xl  rounded-2xl"
              }`}>

<img
                        alt=""
                        src={`${Image_URL}${item?.image}`}
                        className={`h-full w-full  
                       md:rounded-none rounded-2xl
                          object-cover
                     
                        `}
                      />


             
      
            
            
                  {/* <div className="w-6/12 h-full">
                    <img
                      alt=""
                      src={require("../../../../assets/images/7.png")}
                      className={`h-full object-cover ${
                        language === "_ar" ? "" : "rounded-tr-xl"
                      }`}
                    />
                  </div> */}
          
                {/* <div className="flex items-center w-full">
                  <div className="w-6/12 h-full">
                    <img
                      alt=""
                      src={require("../../../../assets/images/8.png")}
                      className={`h-full object-cover ${
                        language === "_ar" ? "rounded-bl-xl" : ""
                      }`}
                    />
                  </div>
                  <div className="w-6/12 h-full">
                    <img
                      alt=""
                      src={require("../../../../assets/images/6.png")}
                      className={`h-full object-cover ${
                        language === "_ar" ? "" : "rounded-br-xl"
                      }`}
                    />
                  </div>
                </div> */}
              </div>
            </div>
            </SwiperSlide>
            )):
            (!loading && <div className="flex w-full h-[300px] justify-center text-F20 font-semibold items-center">
             {GetAppText("no_data")}
               </div>)
          }
 
      </Swiper>}
    </div>
  );
};
export default UrgentProjects