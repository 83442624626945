import { useSelector } from "react-redux";
import en from "../utils/Language/en";
import ar from "../utils/Language/ar";
import { store } from "../store";

export const GetAppText = (key) => {
  const currentLang = store.getState().language.value;

  switch (currentLang) {
    case "_en":
      return en[key];
    case "_ar":
      return ar[key];
    default:
      return en[key];
  }
};

export const StyleClass = () => {
  const currentLang = store.getState().language.value;

  return currentLang === "_ar" ? "flex-row-reverse space-x-reverse" : "";
};

export const secondaryColor = "#E49E4E";

export const ValidationErrors = {
  email: {
    alreadyExists: 'the-email-has-already-been-taken',
    invalid: 'the-email-field-must-be-a-valid-email-address.',
    invalidEmail:'the-email-must-be-a-valid-email-address.'
  },
  phoneNumber: {
    alreadyExists: 'this-phone-number-is-already-in-use',
    notComplete: 'the-phone-number-must-be-exactly',
    notExists: 'user-signup-required',
    alreadyTaken:"the-phone-number-has-already-been-taken.",
  },
  cprNumber: {
    alreadyExists: 'the-cpr-number-has-already-been-taken',
    notComplete: 'the-cpr-number-field-must-have-at-least-9-digits',
    limit:"the-cpr-number-must-be-at-least-9."
  },
  Otp:{
    notValid:"verification-code-not-valid-or-phone-not-exist"
  },
  updateProfile:{
    update:"update-user-info",
    phoneUpdated:"phone-successfully-updated"
  },
  addCartItem:{
    added:"cart-item-added-successfully",
    limit_error:"the-item-price-must-not-exceed-50000.",
    updated:"cart-item-updated-successfully",
    remove:"cart-item-remove-successfully",
    lessAmount:"the-item-price-must-not-be-less-than-1.",
    exceedGoalAmount:"your-donated-amount-exceeds-the-remaining-goal-amount."
  }
};


export const convertToArabicNumbers = (value) => {
    const arabicToEnglishDigits = {
      "٠": "0",
      "١": "1",
      "٢": "2",
      "٣": "3",
      "٤": "4",
      "٥": "5",
      "٦": "6",
      "٧": "7",
      "٨": "8",
      "٩": "9",
    };
  

  // return value
  return String(value)?.split("")?.map((char) => (arabicToEnglishDigits[char] !== undefined ? arabicToEnglishDigits[char] : char))?.join("");
};
export const convertToNumbersInArabic = (value) => {

  const arabicNumbersMap = {
    "0": "٠",
    "1": "١",
    "2": "٢",
    "3": "٣",
    "4": "٤",
    "5": "٥",
    "6": "٦",
    "7": "٧",
    "8": "٨",
    "9": "٩",
  };

  return value?.toString()?.split("")?.map((char) => arabicNumbersMap[char] || char)?.join("");
};